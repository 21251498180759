.earningGuides {
    text-align: center;
    h2 {
        text-transform: uppercase;
        font-weight: 700;
    }
    p {
        margin: .5rem 0 3rem 0;
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 400;
        a {
            color: #FF5986;
            display: inline-block;
        }
    }
    p, a, h3 { text-transform: capitalize; }
    p, a { color: rgba($color: #fff, $alpha: .5); }
    .content {
        padding: 0 1rem 10rem;
        margin-top: 2rem;
        position: relative;
        overflow: hidden;
        h3 {
            margin-bottom: 1rem;
            font-size: clamp(15px, 4vw, 18px);
            font-weight: 700;
            text-shadow: 0 0 .3em rgba($color: #000, $alpha: .5);
        }
        .bcgFig {
            & > div {
                position: absolute;
            }
            img {
                width: 100%;
                user-select: none;
                pointer-events: none;
            }
            .ufo {
                width: 600px;
                bottom: 0;
                transform: translate(-10%, 65%);
                img:last-child {
                    display: none;
                }
            }
            .earth {
                width: 150px;
                bottom: 0;
                right: 0;
                transform: translate(20%, 20%);
            }
        }
        .videos {
            z-index: 1;
            position: relative;
            .single {
                margin: 2rem 0;
                video {
                    z-index: 1;
                    box-shadow: 0 0 1em rgba($color: #000, $alpha: .5);
                    border-radius: .5em;
                    width: clamp(200px, 100%, 400px);
                    height: 170px;
                    &[poster] {
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 900px) {
    .earningGuides {
        text-align: left;
        .content {
            padding: 0 10rem 0 1.5rem;
            .bcgFig {
                .ufo {
                    transform: translate(0%, 30%);
                    right: 0;
                    width: 900px;
                    img:last-child {
                        display: block;
                        width: 500px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        transform: translate(-10%, -5%);
                    }
                }
                .earth {
                    width: 300px;
                    right: 45%;
                    transform: translate(0%, 40%);
                }
            }
            .videos {
                .single {
                    video {
                        height: 220px;
                    }
                }
            }
        }
    }
}
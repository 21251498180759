.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  transition: 1s ease-in-out;
  display: flex;
  margin: 0;
  @media (max-width: 992px) {
    transform: translateX(0);
    position: fixed;
    top: 170px;
    left: 0;
    right: 0;
    height: 75vh;
    width: 100vw;
    //  bottom: 0;
    @media (max-height: 500px) {
      top: 0;
      height: 116vh;
      //bottom: -100px;
    }
  }
  .contentContainer {
    z-index: 2;
    flex: 1;
    height: 100%;
    background-color: var(--black-color);
    padding: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      * {
        margin: 0 12px;
        cursor: pointer;
      }
      @media (max-width: 992px) {
        margin-top: 16px;
      }
      .blur {
        filter: blur(1px);
      }
    }
    .onlineContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px 0;
      .icon {
        height: 8px;
        width: 8px;
        background-color: var(--green-color);
        border-radius: 4px;
        margin-right: 6px;
      }
      .count {
        font-size: 14px;
        font-weight: 700;
        opacity: 0.5;
      }
    }
    .messageContainer {
      flex: 1;
      position: relative;
      overflow: hidden;
      .messageWrapper {
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        word-break: break-all;
        overflow-y: auto;
        .messageHolder {
          margin: 12px 0;
        }
      }
      .messageWrapper::-webkit-scrollbar {
        // width: 0px;
        // background: transparent; /* make scrollbar transparent */
        width: 10px;
      }
      /* Handle */
      .messageWrapper::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 24px;
      }
      .messageWrapper::-webkit-scrollbar-track {
        background: transparent;
        margin: 12px;
      }
    }
  }
  .collapser {
    position: absolute;
    top: 30px;
    z-index: 1;
    width: 46px;
    height: 25px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background-color: var(--pink-color);
    left: -80px;
  }
  .chatSvg {
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: -60px;
    // background-color: var(--dark-blue);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-right: 10px;
    .chat {
      width: 84px;
      height: 84px;
      transition: all 0.2s ease-in-out;
      &.ting {
        width: 112px;
        height: 112px;
        transition: all 0.2s ease-in-out;
        transform: translateX(-40px);
      }
    }
  }
  .arrow {
    margin-left: 6px;
    transform: rotate(180deg);
  }
}
.active {
  transform: translateX(-371px);
  transition: 1s ease-in-out;

  @media (max-width: 992px) {
    display: none;
    transition: 1s ease-in-out;
  }
  .collapser {
    position: absolute;
    top: 30px;
    left: -30px;
    z-index: 1;
    width: 46px;
    height: 25px;
    background-color: #2b3857;
    border-radius: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
      display: none;
    }
    .arrow {
      transform: rotate(0deg);
    }
  }

  .chatSvg {
    display: none;
  }
}

@media (max-width: 992px) {
  .container {
  }
}

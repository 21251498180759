.container {
  display: flex;
  margin-bottom: 12px;
  .avatarContainer {
    .avatar {
      height: 48px;
      width: 48px;
      object-fit: cover;
      border-radius: 24px;
    }
  }
  .content {
    margin-left: 12px;
    .nameContainer {
      display: flex;
      align-items: center;
      .level {
        margin-right: 8px;
        padding: 2px 6px;
        background-color: var(--gray-color);
        opacity: 0.8;
        color: var(--secondary-color);
        border-radius: 5px;
        font-size: 12px;
      }
      .name {
        font-size: 18px;
        font-weight: 700;
      }
    }
    .message {
      margin-top: 4px;
      font-size: 14px;
      color: var(--gray-color);
    }
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  .popupContainer {
    position: absolute;
    width: 99%;
    animation: shake 0.32s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    left: 0;
    top: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    .popup {
      border: 1px solid rgba(52, 93, 157, 0.8);
      //box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(52, 93, 157, 0.6);
      border-radius: 30px;

      .loginPopup {
        display: flex;
        flex-direction: column;
        //  align-items: center;
        justify-content: flex-start;
        padding-top: 10px;
        height: 140px;
        width: 400px;
        background: var(--primary-color);

        border: 1px solid rgba(255, 246, 246, 0.05);
        box-sizing: border-box;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        .login {
          text-align: center;
          font-size: 24px;
          margin: 15px;
        }
        .buttonContainer {
          display: flex;
          justify-content: space-evenly;
          button {
            .buttonContent {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              height: 100%;
              .icon {
                margin-right: 10px;
              }
            }
          }
          .buttonContent {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            min-width: 90px;
          }
        }
      }
    }
    .offerContainer {
      padding: 15px 20px;
      &.active {
        filter: blur(10px);
        pointer-events: none;
      }
    }
  }
}

.backgroundContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 64px auto;
  .image {
    position: relative;
    .face {
      width: 100%;
      height: auto;
    }
    .leftEye {
      position: absolute;
      top: 30%;
      left: 25%;

      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
    .rightEye {
      position: absolute;
      top: 30%;
      right: 25%;
      -webkit-animation: spin 2s linear infinite;
      -moz-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;
    }
  }
  .title {
    margin: 12px auto;
    font-size: 56px;
    opacity: 0.6;
    text-align: center;
    @media (min-width: 552px) {
      font-size: 43px;
    }
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .navbarContainer {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;

    @media (min-width: 992px) {
      z-index: 1000;
    }
  }
  .contentWrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    .earningsContainer {
      position: relative;
      padding-bottom: 100px;
    }
    .row {
      display: flex;
      flex: 1;
      position: relative;
      .contentContainer {
        //margin-top: 27px;
        //padding-top: 64px;
        flex: 1;
        overflow: hidden;
        background-color: var(--black-color);
        @media (min-width: 992px) {
          padding-top: 34px;
        }
      }
      .chatContainer {
        position: fixed;
        //right: -371px; // Remove right and uncomment this to show chat
        right: -100000px;
        top: 170px;
        bottom: 0;
        z-index: 2998;
        flex: 0;
        @media (max-height: 500px) {
          z-index: 3000;
        }
        @media (min-width: 992px) {
          z-index: 100;
        }
      }
    }
    .bottomNavContainer {
      position: sticky;
      z-index: 3001;
      bottom: 0;
      @media (min-width: 992px) {
        display: none;
      }
      @media (max-height: 500px) {
        z-index: 2999;
      }
    }
    .popupContainer {
      position: absolute;
      height: 100%;
      width: 100%;
      pointer-events: none;
      top: 0;
      left: 0;
      z-index: 50000;
    }
  }
}

@media (min-width: 992px) {
  .container {
    .contentWrapper {
      .popupContainer {
        position: fixed;
      }
    }
  }
}

.loadingWrapper {
  padding-top: 120px;
  padding-bottom: 120px;
}
.backgroundContainer {
  .mainBlogContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column-reverse;
      margin-bottom: 12px;
    }
    .mainBlog {
      flex-basis: auto;
      height: auto;
      margin: 40px;
      &:hover {
        cursor: pointer;
      }
      &:hover .blogTitle {
        text-decoration: underline;
      }
      .blogPic {
        width: 500px;
        height: auto;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
        border-radius: 30px;
        &:hover {
          opacity: 0.8;
        }
        @media (max-width: 600px) {
          width: 100%;
          height: auto;
          margin: 0;
        }
      }
      .dateContainer {
        display: inline-flex;
        @media (max-width: 620px) {
          margin-top: 8px;
        }
        .timeDate {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.5;
          padding-right: 12px;
          @media (max-width: 620px) {
            font-size: 14px;
          }
        }
        .timeline {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.5;
          @media (max-width: 620px) {
            font-size: 14px;
          }
        }
      }
      .blogTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        text-transform: uppercase;
        color: #ffe483;
        margin: 20px auto;
        @media (max-width: 620px) {
          font-size: 22px;
          line-height: 36px;
        }
      }
      .blogSubtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 25px;
        text-transform: capitalize;
        color: #fafafa;
        @media (max-width: 620px) {
          font-size: 18px;
        }
      }
    }
    .cloud {
      display: flex;
      justify-content: flex-end;
      width: 60%;
      height: auto;
      @media (max-width: 600px) {
        width: 100%;
        height: auto;
      }
    }
  }
  .swiperContainer {
    margin-bottom: 64px;
    .swiper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 24px;
      justify-content: center;
      align-items: flex-start;
      .otherBlogsContainer {
        max-width: 350px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 738px) {
          flex-direction: row;
          max-width: 100%;
          min-width: 100vw;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 24px;
        }
        &:hover {
          cursor: pointer;
        }
        &:hover .blogTitle {
          text-decoration: underline;
        }
        .imageWrapper {
          width: 300px;
          height: 200px;
          @media (max-width: 738px) {
            min-width: 100px;
            min-height: 100px;
            max-width: 100px;
            max-height: 100px;
          }
          .blogImage {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            border: 1px solid #000000;
            box-sizing: border-box;
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
            border-radius: 30px;
            object-fit: cover;

            &:hover {
              opacity: 0.7;
            }
          }
        }
        .descriptionContainer {
          padding-left: 12px;
          .dateContainer {
            display: flex;
            justify-content: center;
            @media (max-width: 738px) {
              padding-left: 6px;
              justify-content: flex-start;
            }
            .timeDate {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
              padding-right: 12px;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
            .timeline {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
          }

          .blogTitle {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-transform: uppercase;
            color: #ffe483;
            margin: 20px auto;
            margin-left: 8px;
            @media (max-width: 768px) {
              text-align: inherit;
              font-size: 14px;
            }
          }
          .blogSubtitle {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-transform: capitalize;
            color: #fafafa;
            margin-left: 8px;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.mainWrapper {
  position: relative;
  .wrapper {
    .lightBackground {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 32px 64px;
      background-color: var(--dark-blue);
      .userContainer {
        width: 100%;
        display: flex;
        .avatarContainer {
          .avatar {
            height: 48px;
            width: 48px;
            object-fit: cover;
            border-radius: 24px;
          }
        }
        .content {
          margin-left: 12px;
          flex: 1;
          overflow: hidden;
          .nameContainer {
            display: flex;
            align-items: center;
            .level {
              margin-right: 8px;
              padding: 2px 6px;
              background-color: var(--gray-color);
              opacity: 0.8;
              color: var(--secondary-color);
              border-radius: 5px;
              font-size: 12px;
            }
            .name {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .xp {
            margin-top: 8px;
            font-size: 14px;
            color: var(--gray-color);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .progresContainer {
        margin: 20px 0;
      }
      .cardsContainer {
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .card {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 15px;
          max-width: 180px;
          width: 100%;
          padding: 12px;
          margin: 8px;
          .value {
            margin-bottom: 12px;
            text-align: center;
            font-size: 18px;
            font-weight: 700;
          }
          .label {
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            opacity: 0.5;
          }
        }
      }
      .graphContainer {
        .label {
          font-size: 14px;
          text-align: center;
          margin-bottom: 24px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 32px;
      }
      .flexRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          font-size: 15px;
          font-weight: 700;
        }
        .button {
          display: flex;
          align-items: center;
          padding: 8px 20px;
          background-color: var(--secondary-color);
          border: none;
          font-size: 13px;
          border-radius: 24px;
          font-weight: 700;
          .icon {
            margin-left: 4px;
          }
        }
      }
      .levelContainer {
        margin-top: 24px;
        margin-bottom: 64px;
        .buttondesktop {
          display: none;
        }
      }
      .flexRowOptional {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 24px 0;
        .label {
          font-size: 15px;
          opacity: 0.5;
          white-space: nowrap;
          margin: 0 16px;
        }
        .submit {
          border: none;
          font-size: 13px;
          font-weight: 700;
          border-radius: 24px;
          padding: 8px 12px;
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
      .link {
        background-color: rgba(255, 255, 255, 0.1);
        color: rgba(255, 255, 255, 0.6);
        text-align: center;
        padding: 12px 24px;
        border-radius: 32px;
        margin: 24px 0;
        font-size: 15px;
      }
    }
    .darkBackground {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 12px 64px;
      background-color: var(--black-color);
      .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        @media (max-width: 550px) {
          flex-direction: column;
          & > * {
            width: 100%;
            text-align: center;
            margin: 12px;
          }
        }
      }
      .switchContainer {
        margin-top: 24px;
      }
    }
  }

  .confirmContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1000;
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dark-blue);
      border-radius: 24px;
      filter: drop-shadow(0px 4px 50px rgba(255, 255, 255, 0.2));
      .content {
        height: auto;
        width: 350px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .titleContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary-color);
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          padding: 6px 16px;
          .title {
            color: #fff;
            font-size: 20px;
          }
        }
        .infoContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .face {
            margin-top: 12px;
            width: 84px;
            height: auto;
          }
          .description {
            text-align: center;
            margin: 12px 16px;
            font-size: 16px;
          }

          .buttons {
            display: flex;
            justify-content: center;
            .buttonContainer {
              margin: 8px 16px;
              margin-bottom: 24px;
              display: flex;
              .buttonText {
                padding: 8px 16px;
                font-size: 18px;
                background-color: var(--secondary-color);
                border-radius: 24px;
                &:hover {
                  background-color: #d71838;
                  cursor: pointer;
                }
              }
              .buttonTextGray {
                padding: 8px 16px;
                font-size: 18px;
                background-color: transparent;
                border: 1px solid gray;
                border-radius: 24px;
                transition: all 1s ease;
                transform: scale(1);
                &:hover {
                  background-color: gray;
                  transition: all 1s ease;
                  transform: scale(0.5);
                  color: black;
                  cursor: pointer;
                }
              }
            }
          }
          .wheelWrapper {
            position: relative;
            > div:first-child {
              max-width: 300px;
              max-height: 300px;
            }
            .pinger {
              position: absolute;
              pointer-events: none;
              top: 13px;
              right: 5px;
              z-index: 100;
              width: 64px;
              height: auto;
            }
          }
          .spinButton {
            cursor: pointer;
            margin: 12px auto;
          }
        }
      }
    }
  }
  .rouletteContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1000;
    @media (max-width: 992px) {
      z-index: 5000;
    }
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dark-blue);
      border-radius: 24px;
      filter: drop-shadow(0px 4px 50px rgba(255, 255, 255, 0.2));
      .content {
        height: auto;
        width: 550px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        @media (max-width: 992px) {
          width: 300px;
        }
        .titleContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary-color);
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          padding: 6px 16px;
          .title {
            color: #fff;
            font-size: 20px;
          }
        }
        .infoContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .description {
            text-align: center;
            margin: 12px 56px;
            font-size: 16px;

            @media (max-width: 992px) {
              margin: 8px 4px;
            }
          }
          .coins {
            color: var(--secondary-color);
            font-size: 20px;
          }
          .buttons {
            display: flex;
            justify-content: center;
            .buttonContainer {
              margin: 8px 16px;
              margin-bottom: 24px;
              display: flex;
              .buttonText {
                padding: 8px 16px;
                font-size: 18px;
                background-color: var(--secondary-color);
                border-radius: 24px;
                &:hover {
                  background-color: #d71838;
                  cursor: pointer;
                }
              }
              .buttonTextGray {
                padding: 8px 16px;
                font-size: 18px;
                background-color: transparent;
                border: 1px solid gray;
                border-radius: 24px;
                transition: all 1s ease;
                transform: scale(1);
                &:hover {
                  background-color: gray;
                  transition: all 1s ease;
                  transform: scale(0.5);
                  color: black;
                  cursor: pointer;
                }
              }
            }
          }
          .wheelWrapper {
            position: relative;
            > div:first-child {
              max-width: 320px;
              max-height: 320px;
              @media (max-width: 992px) {
                max-width: 200px;
                max-height: 200px;
              }
            }
            .pinger {
              position: absolute;
              pointer-events: none;
              top: 13px;
              right: 5px;
              z-index: 100;
              width: 64px;
              height: auto;
              @media (max-width: 992px) {
                width: 38px;
              }
            }
          }
          .spinButton {
            cursor: pointer;
            margin: 12px auto;
          }
          .message {
            margin-bottom: 2px;
            font-size: 18px;
            color: var(--secondary-color);
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .mainWrapper {
    .wrapper {
      margin: 0;
      padding: 0;
      .lightBackground {
        padding: 30px 20px;
        .background {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin: 0 !important;
          padding: 0 !important;
          .cardsContainer {
            flex-direction: row;
          }
        }
        .graphContainer {
          width: 709px;
        }
        .profileContainer {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .userContainer {
            .avatarContainer {
              .avatar {
                width: 70px;
                height: 70px;
                border-radius: 32px;
              }
            }
            .content {
              .nameContainer {
                .level {
                  font-size: 26px;
                }
                .name {
                  font-size: 40px;
                }
              }
              .xp {
                font-size: 22px;
              }
            }
          }
        }
      }
      .darkBackground {
        .buttonsContainer {
          justify-content: flex-start;
          & > * {
            margin: 20px;
          }
        }
      }
      .lightBackground {
        .backgrnd {
          margin: 0 50px;
          .title {
            font-size: 30px;
            text-align: left;
          }
          .flexRow {
            // background-color: rgba(255, 255, 255, 0.1);
            .button {
              display: none;
            }
          }
        }
        .levelContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 35px 30px;
          border-radius: 30px;
          .buttondesktop {
            display: flex;
            align-items: center;
            padding: 8px 20px;
            background-color: var(--secondary-color);
            border: none;
            font-size: 13px;
            border-radius: 24px;
            font-weight: 700;
            .icon {
              margin-left: 4px;
            }
          }
          .link {
            margin: 0;
            width: 50%;
          }
        }
      }
      .switchContainer {
        margin: 24px 30px;
      }
    }
  }
}

.backgroundContainer {
  height: 100%;
  align-items: center;
  position: fixed;
  @media (min-width: 992px) {
    margin-top: 0px;
    position: absolute;
  }
  .container {
    margin-top: 66px;

    overflow-y: auto;
    background-color: var(--black-color);
    height: 100%;
    padding: 32px 20px;
    padding-bottom: 100px;
    @media (min-width: 992px) {
      padding-bottom: 0;
    }
    .titleContainer {
      display: none;
    }
    .title {
      text-align: center;
      margin-top: 32px;
      font-size: 20px;
      font-weight: 700;
    }
    .text {
      text-align: center;
      margin-top: 12px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.4);
      .link {
        display: inline;
        color: var(--secondary-color);
      }
    }
    .warningContainer {
      padding: 12px;
      margin-top: 40px;
      background-color: #c12d1d;
      .warningMessage {
        text-align: center;
        font-size: 16px;
      }
    }
    .formContainer {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .inputContainer {
        margin-bottom: 8px;
      }
      .double {
        grid-column: 2 span;
        @media (max-width: 600px) {
          grid-column: 1 span;
        }
      }
      .sectionTitle {
        grid-column: 3 span;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: -20px;
        margin-top: 12px;
        @media (max-width: 600px) {
          grid-column: 1 span;
        }
      }
    }
    .submitwrapper {
      display: flex;
      justify-content: center;
      margin: 14px auto;
      .submit {
        text-align: center;
        @media (max-width: 600px) {
          grid-column: 1 span;
        }
      }
    }
  }
  .inputwrapperfirst {
    .questionwrapper {
      .questionContainer {
        grid-column: 3 span;
        text-align: center;
        @media (max-width: 600px) {
          grid-column: 1 span;
        }
      }
      .inputsWrapper {
        grid-column: 3 span;
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 600px) {
          grid-column: 1 span;
        }
        .inputWrapper {
          margin: 12px 10px;
          .input {
            margin: 0 5px;
            display: none;
            &:checked ~ .labelContainer {
              background-color: var(--secondary-color);
              border: 1px solid rgba(255, 255, 255, 0.3);
              .label {
                opacity: 1;
              }
            }
          }
          .labelContainer {
            position: relative;
            font-size: 16px;
            background-color: var(--black-color);
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.15);
            border-radius: 30px;
            outline: none;
            width: 100%;
            transition: 0.3s ease;
            cursor: pointer;
            &:hover {
              border: 1px solid rgba(255, 255, 255, 0.3);
            }

            .label {
              opacity: 0.3;
              padding: 12px 18px;
              @media (min-width: 992px) {
                padding: 12px 28px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .backgroundContainer {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4) !important;
    display: flex;
    justify-content: center;
    .container {
      max-width: calc(100% - 48px);
      height: auto;
      width: 60%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-sizing: border-box;
      box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.2);
      border-radius: 30px;
      .titleContainer {
        display: flex;
        justify-content: space-between;
      }
      .title {
        text-align: left;
        margin-top: 12px;
        font-size: 18px;
      }
      .text {
        text-align: left;
        margin-top: 8px;
        font-size: 14px;
      }
      .warningContainer {
        text-align: left;
        padding: 6px;
        margin-top: 14px;
        width: 80%;
        .warningMessage {
          font-size: 14px;
          text-align: left;
        }
      }
      .inputwrapperfirst {
        margin-bottom: 30px;
        display: flex;
        justify-content: space-around;
        .questionContainer {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }
        .submitwrapper {
          margin-right: 0;
        }
      }
    }
  }
}

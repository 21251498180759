.earningGuides {
  text-align: center;
  h2 {
    text-transform: uppercase;
    font-weight: 700;
  }
  p {
    margin: 0.5rem 0 3rem 0;
    font-size: clamp(14px, 3vw, 16px);
    font-weight: 400;
    a {
      color: #ff5986;
      display: inline-block;
    }
  }
  p,
  a,
  h3 {
    text-transform: capitalize;
  }
  p,
  a {
    color: rgba($color: #fff, $alpha: 0.5);
  }
  .content {
    padding: 0 1rem 10rem;
    margin-top: 2rem;
    position: relative;
    overflow: hidden;
    h3 {
      margin-bottom: 1rem;
      font-size: clamp(15px, 4vw, 18px);
      font-weight: 700;
      text-shadow: 0 0 0.3em rgba($color: #000, $alpha: 0.5);
    }
    .bcgFig {
      position: relative;
      .ufo {
        //width: 60%;
        //height: auto;
        position: absolute;
        top: 0;
        right: 0;
        .ufoImg {
          width: 100%;
        }
      }
      .earth {
        position: absolute;
        left: 50%;
        right: 0;
        bottom: -100vh;
        .earthImg {
          position: absolute;
          left: -30%;
          right: 0;
          bottom: -85vh;
          width: 80%;
          opacity: 0.4;
        }
      }
    }
  }
  .videos {
    z-index: 1;
    position: relative;
    .single {
      margin: 2rem 0;
      video {
        z-index: 1;
        box-shadow: 0 0 1em rgba($color: #000, $alpha: 0.5);
        border-radius: 0.5em;
        width: clamp(200px, 100%, 400px);
        height: 170px;
        &[poster] {
          object-fit: cover;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .earningGuides {
    text-align: left;
    .content {
      padding: 0 1.5rem;
      .bcgFig {
        .ufo {
          transform: translate(30%, 20%);
          right: 0;
          width: 1200px;
          img:last-child {
            display: block;
            // width: 600px;
            position: absolute;
            left: 0;
            top: 0;
            transform: translateX(-5%);
          }
        }
        .earth {
          width: 350px;
          right: 40%;
          transform: translate(50%, 40%);
        }
      }
      .videos {
        .single {
          video {
            height: 220px;
          }
        }
      }
    }
  }
}

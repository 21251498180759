.wrapper {
  position: relative;
  .hoverIndicator {
    position: absolute;
    z-index: 10;
    width: 14px;
    height: 14px;
    background: #e94560;
    border: 1px solid #000000;
    border-radius: 32px;
    transform: translateY(-5px);
    transform: translateX(-5px);
  }
  .container {
    width: 100%;
    height: 12px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    overflow: hidden;
    .indicator {
      background: var(--pink-color);
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      height: 100%;
      transition: 0.3s ease;
      width: 0%;
    }
  }
  .item {
    position: absolute;
    //transform: translateX(-50%);
    transform: translateY(-25%);
    .level {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      //text-transform: capitalize;

      transform: translateY(-20px);
      .levelLabel {
        display: none;
        color: #9899a5;
      }
      .multiplier {
        color: var(--pink-color);
      }
    }
    .icon {
      position: relative;
      height: 16px;
      transform: translateX(-50%);
      left: 50%;
    }
    .value {
      margin-top: -4px;
      white-space: nowrap;
      font-weight: 700;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      .coin {
        margin-left: 4px;
      }
    }
  }
}

@media (min-width: 992px) {
  .wrapper {
    .container {
      width: 90vh;
    }
    .item {
      .level {
        .levelLabel {
          display: initial;
          color: #9899a5;
        }
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  padding: 10px;
  flex-wrap: wrap;
  &.active {
    & > .extra {
      transition: all 0.5s ease-in-out;

      //display: block;
      visibility: visible;
      opacity: 1;
      max-height: 1000px;
    }
  }
  .avatarContainer {
    .avatar {
      height: 120px;
      width: 120px;
      object-fit: cover;
      border-radius: 24px;
    }
  }
  .content {
    margin-left: 12px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    .nameContainer {
      display: flex;
      flex-direction: column;
      .name {
        margin-left: 4px;
        font-weight: 700;
        font-size: 22px;
      }
      .description {
        margin-left: 4px;
        margin-top: 8px;
        margin-bottom: 2px;
        font-size: 13px;

        overflow: hidden;
        color: var(--gray-color);
      }

      .readMore {
        font-size: 14px;
        color: var(--gray-color);
        margin: 8px 4px;
      }
      .countries {
        font-size: 14px;
        color: var(--gray-color);
        margin: 8px 4px;
        margin-top: 0;
      }
      .noDisplay {
        display: none;
      }
    }

    .claimButton {
      display: flex;
      align-items: center;
      .btnContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .btn {
          background-color: var(--secondary-color);
          font-size: 15px;
          font-weight: 700;
          padding: 10px 30px;
          border-radius: 30px;
          border: none;
          display: flex;
          align-items: center;
        }
        .svgContainer {
          border-radius: 50%;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          .mobileQrCodeSvg {
            width: 56px;
            height: auto;
          }
        }
      }

      .btnContainerNotAvailable {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .btn {
          background-color: var(--gray-color);
          font-size: 15px;
          font-weight: 700;
          padding: 10px 30px;
          border-radius: 30px;
          border: none;
          display: flex;
          align-items: center;
        }
        .svgContainer {
          border-radius: 50%;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 12px;
          .mobileQrCodeSvg {
            width: 56px;
            height: auto;
          }
        }
      }
      .coinsEarnedIcon {
        margin: 0 4px;
      }
    }
  }
  .extra {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.2s ease-out;
    display: flex;
    justify-content: space-between;
    .description {
      padding-right: 12px;
      transform: translateY(-20px);
      margin-top: 8px;
      font-size: 16px;
      margin-left: 130px;
      overflow: hidden;
      color: var(--gray-color);
      margin: 32px 0px;
      margin-bottom: 0;
    }
    .qrCode {
      align-self: flex-end;
      margin-bottom: 16px;
      min-width: 100px;
      min-height: 100px;
      width: 100px;
      height: 100px;
    }
  }
  &.logedOut {
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0);
      content: "";
      filter: blur(24px);
      z-index: 1;
    }
    .avatarContainer {
      filter: blur(8px);
    }
    .nameContainer,
    .extra {
      filter: blur(3px);
    }
  }
}

@media (min-width: 992px) {
  .container {
    padding: 12px;
    .content {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      .nameContainer {
        .name {
          font-weight: 700;
          font-size: 22px;
        }
        .description {
          font-size: 16px;
        }
      }
    }
  }
}

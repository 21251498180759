.background {
  position: relative;

  .container {
    position: relative;
    .totContainer {
      .leaderContainer {
        .leaderTitle {
          margin-top: 60px;
          text-align: center;
          font-weight: bold;
          font-size: 28px;
          line-height: 21px;
        }
        .leaderDescription {
          margin: 30px 60px;
          font-weight: normal;
          font-size: 18px;
          line-height: 16px;
          text-align: center;
          text-transform: capitalize;
          mix-blend-mode: normal;
          opacity: 0.5;
        }
      }
      .buttonsContainer {
        display: flex;
        justify-content: center;
        a {
          margin: 0 5px;
        }
      }
    }
  }
  .topStars {
    opacity: 0.5;
    position: absolute;
    top: -60px;
    right: 0;
  }
}

@media (min-width: 992px) {
  .background {
    margin-bottom: 92px;
    .container {
      padding-bottom: 48px;
      .totContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        .leaderContainer {
          margin-left: 100px;
          .leaderTitle {
            text-align: left;
          }
          .leaderDescription {
            text-align: left;
            margin: 10px auto;
          }
        }
        .buttonsContainer {
          align-items: center;
          margin-right: 120px;
        }
      }
    }
  }
}

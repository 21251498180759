.backgroundContainer {
  position: relative;
  z-index: 2;
  .container {
    .endContainer {
      .theEndContainer {
        z-index: 2;
        .endTitle {
          margin-top: 40px;
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
        }
      }
      .countDown {
        width: max-content;
        margin: 10px auto;
        object-fit: fill;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1a1a2e;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        .timeChar {
          margin: 10px 5px;
          width: auto;
        }
        .timeNum {
          margin: 10px 5px;
          background: rgba(255, 255, 255, 0.1);
          opacity: 0.8;
          border-radius: 30px;
          padding: 6px;
        }
      }
    }
    .userEarned {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      text-transform: capitalize;
      margin: 0 20%;
      .colorRed {
        color: #e87588;
      }
    }
    .topUsers {
      display: flex;
      flex-direction: column;
      align-content: center;
      flex-wrap: wrap;
      margin: 30px auto;
      .users {
        transform: translateX(-15%);
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #1a1a2e;
        border: 1px solid rgba(233, 69, 96, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(233, 69, 96, 0.3);
        border-radius: 30px;
        min-width: 300px;
        .userRank {
          padding-top: 10px;
          align-self: flex-start;
          text-align: left;
          margin: 0 50px;
          font-weight: bold;
          font-size: 22px;
          line-height: 25px;
          text-transform: capitalize;
          color: #e87588;
        }
        .avatar {
          width: 70px;
          height: 70px;
          border-radius: 80px;
          text-align: center;
        }
        .userName {
          font-weight: bold;
          font-size: 30px;
          line-height: 35px;
          text-align: center;
          margin: 10px 50px;
        }
        .coinsContainer {
          display: flex;

          .coinsAmmount {
            text-align: center;
            padding: 0 30px;
            .inline {
              display: flex;
              flex-direction: row;
              margin: 5px auto;
              align-items: center;
              .ammount {
                font-size: 18px;
              }
              .coinSvg {
                margin: 4px;
              }
            }
            .titleText {
              font-weight: normal;
              font-size: 22px;
              opacity: 0.5;
              margin-bottom: 30px;
            }
          }
        }
      }
      .users2 {
        transform: translateX(10%);
        margin: 30px auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background: #1a1a2e;
        border: 1px solid rgba(233, 69, 96, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(233, 69, 96, 0.3);
        border-radius: 30px;
        min-width: 300px;
        white-space: normal;
        .userRank {
          padding-top: 10px;
          align-self: flex-start;
          text-align: left;
          margin: 0 50px;
          font-weight: bold;
          font-size: 22px;
          line-height: 25px;
          text-transform: capitalize;
          color: #e87588;
        }
        .avatar {
          width: 70px;
          height: 70px;
          border-radius: 80px;
          text-align: center;
        }
        .userName {
          font-weight: bold;
          font-size: 30px;
          line-height: 35px;
          text-align: center;
          margin: 10px 50px;
        }
        .coinsContainer {
          display: flex;

          .coinsAmmount {
            text-align: center;
            padding: 0 30px;
            .inline {
              display: flex;
              flex-direction: row;
              margin: 5px auto;
              align-items: center;
              .ammount {
                font-size: 18px;
              }
              .coinSvg {
                margin: 4px;
              }
            }
            .titleText {
              font-weight: normal;
              font-size: 22px;
              opacity: 0.5;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
    .topTenContainer {
      margin: 30px 30px;
      .topUser {
        margin: 24px auto;
        padding: 15px 12px;
        background: #1a1a2e;
        border: 1px solid rgba(233, 69, 96, 0.1);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(233, 69, 96, 0.15);
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        .topNameContainer {
          display: flex;
          flex-direction: row;
          .numberTen {
            font-weight: bold;
            font-size: 15px;
            line-height: 17px;
            color: #e87588;
          }
          .name {
            font-size: 15px;
            line-height: 17px;
            text-transform: capitalize;
            margin-left: 8px;
          }
        }
        .tenAmmount {
          display: flex;
          flex-direction: row;
          align-items: center;
          .ammount {
            font-size: 15px;
            line-height: 18px;
          }
          .coinSvg {
            margin: 0 4px;
          }
        }
      }
    }
  }
  .stars {
    z-index: 0;
    position: absolute;
    top: 100px;
    right: 0;
  }
  .lighting {
    z-index: 0;
    position: absolute;
    top: 90px;
    left: 0;
  }
  .starsmid {
    position: absolute;
    left: 0;
    bottom: 750px;
  }
  .eye {
    position: absolute;
    bottom: 450px;
    right: 0;
  }
  .DesktopImg {
    display: none;
  }
}

@media (min-width: 992px) {
  .backgroundContainer {
    .container {
      .endContainer {
        .theEndContainer {
          transform: translateY(500px);
        }
        .topUsers {
          padding-bottom: 140px;
          flex-direction: row;
          justify-content: space-between;
          #top {
            transform: translateY(-30%);
          }
          .users {
            transform: translateX(0);
            order: 2;
          }
          .users2 {
            transform: translateX(0);
          }
        }
      }
      .topTenContainer {
        margin: 0 200px;
        margin-top: 100px;
      }
    }

    .stars {
      display: none;
    }
    .lighting {
      display: none;
    }
    .starsmid {
      display: none;
    }
    .eye {
      display: none;
    }
    .DesktopImg {
      display: initial;
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 2;
  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    background: var(--primary-color);
  }
  * {
    position: relative;
    z-index: 1;
  }
  .linksDesktop {
    display: none;
  }
  .menuIconContainerDesktop {
    display: none;
  }
  .menuIconContainer {
    height: 100%;
    display: initial;

    .menuIcon {
      cursor: pointer;
      path {
        transition: 0.3s ease;
      }
    }
  }
  .menuDesktop {
    display: none;
  }
  .menu2 {
    display: none;
  }
  .menu {
    display: initial;
    position: absolute;
    z-index: 0;
    transform: translate(100%, 100%);
    bottom: 0;
    right: 0;
    background-color: var(--primary-color);
    padding: 20px;
    border-bottom-left-radius: 30px;
    transition: 0.3s ease;
    opacity: 0;
    z-index: 1;
    &.active {
      display: initial;
      opacity: 1;
      transform: translate(0, 100%);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    }

    .userContainer {
      display: flex;
      align-items: stretch;
      .avatar {
        height: 40px;
        width: 40px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 10px;
        .switchWrap {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          transform: translateX(-15px);
          margin-top: 12px;
          .input {
            display: none;
            &:checked {
              ~ .switchWrapper {
                .switchIndicator {
                  left: calc(100% - 12px);
                }
              }
            }
          }
          .switchWrapper {
            position: relative;
            height: 24px;
            width: 48px;
            background-color: rgba(255, 255, 255, 0.1);
            margin: 0 12px;
            border-radius: 16px;
            .switchIndicator {
              position: relative;
              height: 16px;
              width: 16px;
              top: 50%;
              left: 12px;
              border-radius: 12px;
              transform: translate(-50%, -50%);
              background-color: var(--secondary-color);
              transition: 0.3s ease;
            }
          }
        }
        .username {
          font-size: 18px;
          opacity: 0.7;
        }
        .coinsContainer {
          display: flex;
          align-items: center;
          .coins {
            font-weight: 600;
            font-size: 16px;
          }
          .dollarSign {
            fill: #f1b31c;
            width: 16px;
            height: auto;
            font-size: 20px;
            margin: 2px auto;
            margin-right: 6px;
            margin-top: 6px;
          }
          .icon {
            margin-left: 4px;
          }
        }
      }
    }
    .separator {
      height: 1px;
      background-color: var(--white-color);
      margin: 14px 0;
      opacity: 0.2;
    }

    .buttonContainer {
      margin-bottom: -20px;
      .menuButton {
        display: block;
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 800;
        margin: 20px 0;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
  }
  .getStartedContainer {
    .hovButton {
      width: auto;
      padding: 10px 32px;
      position: relative;
      display: block;
      text-decoration: none;
      overflow: hidden;
      border-radius: 24px;

      &:hover {
        .wave {
          top: -120px;
        }
      }

      .buttonText {
        position: relative;
        z-index: 2;
        color: white;
        font-size: 15px;
        text-align: center;
      }

      .wave {
        width: 200px;
        height: 200px;
        background-color: rgba(201, 39, 14, 1);
        box-shadow: inset 0 0 50px rgba(233, 69, 96, 0.5);
        position: absolute;
        left: -40px;
        top: -85px;
        transition: 0.4s;

        &::before,
        &::after {
          width: 200%;
          height: 200%;
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -75%);
        }

        &::before {
          border-radius: 45%;
          background-color: rgba(233, 69, 96, 1);
          animation: wave 5s linear infinite;
        }

        &::after {
          border-radius: 40%;
          background-color: rgba(233, 69, 96, 0.5);
          animation: wave 10s linear infinite;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    .logoContainer {
      display: flex;
    }
    .getStartedContainer {
      .hovButton {
        width: auto;
        padding: 10px 32px;
        position: relative;
        display: block;
        text-decoration: none;
        overflow: hidden;
        border-radius: 24px;

        &:hover {
          .wave {
            top: -120px;
          }
        }

        .buttonText {
          position: relative;
          z-index: 2;
          color: white;
          font-size: 15px;
          text-align: center;
        }

        .wave {
          width: 200px;
          height: 200px;
          background-color: rgba(201, 39, 14, 1);
          box-shadow: inset 0 0 50px rgba(233, 69, 96, 0.5);
          position: absolute;
          left: -40px;
          top: -85px;
          transition: 0.4s;

          &::before,
          &::after {
            width: 200%;
            height: 200%;
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -75%);
          }

          &::before {
            border-radius: 45%;
            background-color: rgba(233, 69, 96, 1);
            animation: wave 5s linear infinite;
          }

          &::after {
            border-radius: 40%;
            background-color: rgba(233, 69, 96, 0.5);
            animation: wave 10s linear infinite;
          }
        }
      }
    }
    .menuIconContainer {
      display: none;
    }
    .menuIconContainerDesktop {
      z-index: 3;
      display: initial;
      background: #2d3751;
      border-radius: 30px;
      padding: 5px 10px;

      .userContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .switchContainer2 {
          display: flex;
          align-items: center;
          .input2 {
            display: none;
            &:checked {
              ~ .switchWrapper2 {
                .switchIndicator2 {
                  left: calc(100% - 12px);
                }
              }
            }
          }
          .switchWrapper2 {
            position: relative;
            height: 24px;
            width: 48px;
            background-color: rgba(255, 255, 255, 0.1);
            margin: 0 12px;
            border-radius: 16px;
            .switchIndicator2 {
              position: relative;
              height: 16px;
              width: 16px;
              top: 50%;
              left: 12px;
              border-radius: 12px;
              transform: translate(-50%, -50%);
              background-color: var(--secondary-color);
              transition: 0.3s ease;
            }
          }
        }
        .separatorVertical {
          margin: 0 10px 0 5px;
          opacity: 0.1;
          border: 2px solid #ffffff;
          height: 20px;
        }
        .avatar {
          width: 42px;
          height: 42px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }
        .content {
          display: flex;
          align-items: center;
        }
        .coins {
          font-size: 20px;
          padding: 0 2px;
        }
        .dollarSign {
          fill: #f1b31c;
          width: 18px;
          height: auto;
          font-size: 20px;
          margin: 2px auto;
          margin-right: 6px;
        }
        .icon {
          width: 16px;
          height: auto;
          margin-right: 10px;
        }
        .username {
          margin: 0 10px;
          font-weight: bold;
          font-size: 22px;
        }
        .dropDown {
          margin: 0 10px;
        }
      }
    }
    .menu2 {
      display: initial;
      position: absolute;
      z-index: 2;
      bottom: 32px;
      right: 36px;
      background-color: #2d3751;
      margin: 0;
      border-radius: 0 0 30px 30px;
      padding: 0 10px;
      transition: 0.3s ease;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transform: translateY(100%);

      &.active2 {
        max-height: 600px;
        display: initial;
        opacity: 1;
        padding-bottom: 30px;
      }
    }
    .buttonContainerDesktop {
      margin-bottom: -20px;
      .menuButtonDesktop {
        text-align: center;
        display: block;
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 800;
        margin: 20px 0;
        cursor: pointer;
        transition: 0.3s ease;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }

    .linksDesktop {
      display: flex;
      align-items: center;
      .iconWrapper {
        display: inline;

        .buttContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 40px;
          .icon {
            width: 32px;
            height: auto;
          }
          .labelNoColor {
            color: #ffffff;
            opacity: 0.7;
            font-size: 22px;
            font-style: normal;
            font-weight: bold;
            &:hover {
              color: #e94560;
              opacity: 1;
            }
          }
          .label {
            margin: 8px;
            font-size: 22px;
            font-style: normal;
            font-weight: bold;
            &:hover {
              color: #e94560;
            }
          }
        }
        &.active {
          .label {
            color: #e94560;
          }
          .icon {
            path {
              fill: #e94560;
            }
          }
        }
      }
    }
  }
  .kefAmmount {
    font-size: 16px;
    margin-left: 12px;
    color: #ffffff;
    opacity: 0.7;
    font-weight: bold;
  }
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

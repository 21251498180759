.container {
  background-color: var(--dark-blue);
  padding: 20px;
  border: 1px solid rgba(22, 33, 62, 0.1);
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.4);
  @media (max-width: 992px) {
    padding-bottom: 100px;
    margin: 0;
  }
  .inputContainer {
    display: flex;
    background-color: var(--black-color);
    padding: 8px;
    padding-left: 24px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    @media (max-width: 992px) {
      flex-direction: row;
      padding: 0px;
    }
    .chatButton {
      @media (max-width: 992px) {
        display: flex;
        flex-direction: row;
        padding: 4px 14px;
        margin: 6px 10px;
        font-size: 14px;
      }
    }
    .input {
      flex: 1;
      background-color: transparent;
      border: none;
      font-size: 16px;
      outline: none;
      &::placeholder {
        opacity: 0.7;
      }
      @media (max-width: 420px) {
        // margin-bottom: 12px;
        //margin-top: 4px;
        margin-left: 12px;
      }
    }
  }
  .iconsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    .iconContainer {
      margin: 0 16px;
      svg {
        height: 24px;
        width: auto;
      }
    }
  }
}

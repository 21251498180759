footer {
  background-color: #1a1e37;
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
  padding: 0 1.3rem;
  overflow: hidden;
  text-align: left;
  z-index: 2;
  @media (max-width: 975px) {
    height: 100%;
    margin-top: 0;
  }
  .footerLinks {
    padding-top: 64px;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    z-index: 2;
    @media (max-width: 975px) {
      grid-template-columns: 1fr;
      width: 100%;
      padding-bottom: 36px;
    }
    .news {
      margin: 0 auto;
      box-sizing: content-box;
      grid-column-end: span 2;
      grid-row-end: span 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 45px;
      @media (max-width: 975px) {
        grid-column-end: span 1;
        grid-row-end: span 1;
        margin: 0;
        justify-content: center;
        align-items: center;
      }
      .fomo {
        font-weight: bold;
        font-size: 36px;
        line-height: 42px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
      .subFomo {
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        margin: 7px 0;
        @media (max-width: 975px) {
          text-align: center;
          max-width: 80%;
        }
      }

      .emailContainer {
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;
        background-color: var(--primary-color);
        border-radius: 30px;
        border: 1px solid #000000;
        flex: 1;
        .newsLetter {
          padding: 10px 30px;
          background: #e94560;
          //border: 1px solid #000000;
          border: none;
          box-sizing: border-box;
          border-radius: 30px;
          font-weight: 700;
          @media (max-width: 975px) {
            // margin: 24px 0;
          }
        }
        .emailInput {
          padding: 10px;
          border: none;
          font-size: 14px;
          color: white;
          background-color: var(--primary-color);
          &:focus {
            border: none;
            outline: none;
          }
        }
      }
    }
    .fingerLeft {
      position: absolute;
      top: 50%;
      left: 11%;
      width: 65px;
      height: 68px;
      background-image: url(../../assets/Onboarding/leftFinger.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 9999999;
      @media (max-width: 750px) {
        width: 60px;
        height: 60px;
      }
      @media (max-width: 550px) {
        width: 50px;
        height: 50px;
      }
    }
    .fingerRight {
      position: absolute;
      top: 50%;
      left: 9%;
      width: 65px;
      height: 68px;
      background-image: url(../../assets/Onboarding/rightFinger.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 9999999;
      @media (max-width: 750px) {
        width: 60px;
        height: 60px;
      }
      @media (max-width: 550px) {
        width: 50px;
        height: 50px;
      }
    }
    .smileFace {
      position: absolute;
      top: 55%;
      left: 7%;
      width: 150px;
      height: 150px;
      background-image: url(../../assets/Onboarding/smileyFace.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 2;
      z-index: 99999999;
      @media (max-width: 550px) {
        width: 90px;
        height: 90px;
      }
    }
    .links a {
      background-color: transparent;
      display: block;
      padding: 0;
      &:active {
        transform: scale(0.9);
      }
    }
    .links a h3 {
      color: #8c8e9b;
      font-size: clamp(13px, 4vw, 20px);
      font-weight: 700;
      text-transform: capitalize;
    }
    .socialLinks {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      @media (max-width: 975px) {
        order: 4;
        align-items: center;
      }
      a {
        background-color: transparent;
        padding: 0;
      }
      h3 {
        margin-bottom: 0.5rem;
        font-weight: 700;
      }
      .social__links {
        margin-top: 0;
        display: flex;
        flex-direction: column;

        z-index: 2;
        @media (max-width: 975px) {
          flex-direction: row;
        }
        a {
          margin-top: 8px;
          //margin: 0 0.5rem;
          @media (max-width: 975px) {
            margin: 8px;
          }
          img {
            width: 28px;
            height: auto;
          }
        }
      }
    }
    .dot {
      color: #1a1a2e;
      user-select: none;
      pointer-events: none;
    }
    .links {
      @media (max-width: 975px) {
        margin: 12px;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .links > h3 {
        margin-bottom: 0.5rem;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }

  .bcgSvg {
    svg {
      opacity: 0.3;
    }
    img,
    svg {
      pointer-events: none;
      user-select: none;
    }
    .bottomLeftFigure {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 350px;

      img {
        display: block;
        width: 100%;
        height: auto;
        //opacity: 0.7;
        @media (max-width: 975px) {
          display: none;
        }
      }
    }
  }
  .rightsContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    .year {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.7;
    }
  }
  .kefAmmount {
    margin-top: 64px;
    font-weight: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.7;
    @media (max-width: 975px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}

.hello {
  color: red;
}

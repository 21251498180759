@font-face {
  font-family: "Whitney";
  src: url(fonts/Whitney-Black.otf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url(fonts/Whitney-Bold.otf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url(fonts/Whitney-Medium.otf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url(fonts/Whitney-Semibold.otf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

:root {
  --primary-color: #0f3460;
  --secondary-color: #e94560;
  --dark-blue: #16213e;
  --light-blue: #2d3751;
  --black-color: #1a1a2e;
  --gray-color: #c0c3cb;
  --green-color: #33b540;
  --white-color: #ffffff;
  --pink-color: #e87588;
}

body {
  margin: 0;
  font-family: "Whitney";
  color: var(--white-color);
  overflow-x: hidden;
}

* {
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--white-color);
  line-height: 1.2em;
}

a,
label,
button {
  display: block;
  cursor: pointer;
}

.button {
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 15px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  transition: 0.3s ease;
}
.button:hover {
  transform: scale(0.95);
}

.button-secondary {
  background-color: var(--secondary-color);
  font-size: 15px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  transition: 0.3s ease;
}
.button-secondary:hover {
  transform: scale(0.9);
}

.button-inactive {
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 15px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 30px;
  opacity: 0.8;
  border: none;
  color: var(--gray-color);
}

.button-inactive:hover {
  transform: scale(0.95);
}

/* ///////////////////*/

.introjs-button {
  color: #3885f8 !important;
  background-color: transparent !important;
  background-image: none !important;
  text-shadow: none !important;
  padding: 3px 10px !important;
  font-family: "Roboto";
  font-size: 12px !important;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
  border: none !important;
}

.introjs-disabled,
.introjs-disabled.introjs-button:hover {
  background-color: transparent !important;
  color: lightgrey !important;
}

.introjs-button:hover {
  background-color: none !important;
  color: #94baf1 !important;
  border: none !important;
  text-decoration: none;
  box-shadow: none !important;
}

.introjs-button:focus,
.introjs-button:active {
  border: none !important;
  box-shadow: none !important;
}

.introjs-disabled,
.introjs-disabled:hover {
  background-color: none !important;
}

.introjs-helperLayer {
  box-shadow: rgb(119 118 118 / 10%) 0px 0px 1px 2px,
    rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}

.introjs-tooltip {
  padding: 5px 10px 10px !important;
}

.introjs-tooltiptext {
  padding: 0px 25px 5px 10px;
  font-weight: lighter;
  font-family: "Roboto";
  text-align: left;
  font-size: 12px;
  color: black;
}

.introjs-tooltip-header {
  padding: 0;
}
.introjs-skipbutton {
  padding: 0;
}

.introjs-skipbutton:hover {
  color: #3885f8;
}

.introjs-bullets {
  display: none;
}

.introjs-tooltipbuttons {
  padding: 0;
}

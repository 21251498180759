.container {
  .tabContainer {
    background: var(--dark-blue);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px 30px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 128px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 12px;
    padding: 0 24px;
    .tab {
      border: none;
      background-color: transparent;
      font-size: 18px;
      font-weight: 700;
      padding: 14px;
      transition: 0.3s ease;
      &.active {
        color: var(--secondary-color);
      }
    }
  }
  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
  }
  .inputContainer {
    margin-bottom: 64px;
  }
  .tableContainer {
    position: relative;
    width: calc(100% + 128px);
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    .tableHead {
      padding: 20px 40px;
      padding-right: 12px;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
      border-radius: 0px 30px 30px 0px;
      .label {
        margin: 20px 0;
        font-weight: 15px;
        font-weight: 700;
      }
    }
    .tableContent {
      flex: 1;
      display: flex;
      overflow-y: auto;
      padding: 20px 10px;
      .tableItem {
        min-width: 140px;
        background-color: rgba(255, 255, 255, 0.1);
        margin: 0 6px;
        padding: 0 12px;
        border-radius: 15px;
        .value {
          margin: 20px 0;
          font-weight: 15px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    .tabContainer {
      justify-content: flex-start;
    }
    .buttonsContainer {
      justify-content: flex-start;
      align-items: center;

      & > * {
        margin: 14px !important;
      }
    }
  }
  .inputContainer {
    width: 270px;
  }
  .tableContainer {
    flex-direction: column;
    .tableHead {
      display: flex;
      justify-content: space-around;
      padding: 0 40px !important;
      padding-right: 65px !important;
      box-shadow: none !important;
    }
  }
  .tableContent {
    flex-direction: column;
    padding: 0 !important;

    .tableItem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 300px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      background-color: transparent !important;
      border-radius: 0 !important;
      .value {
        margin: 10px auto !important;
      }
    }
  }
}

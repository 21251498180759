.wrapperContainer {
  .container {
    background: linear-gradient(183.82deg, #303041 -0.06%, #3a3a53 19.43%);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
    .kefgg {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .KefContainer {
        display: flex;
        align-items: center;
        padding: 16px;
        margin-top: 36px;
        @media (max-width: 600px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 24px;
        }
        .text {
          padding: 0 12px;
          @media (max-width: 1000px) {
            font-size: 26px;
            text-align: center;
          }
        }
        .logoContainer {
          flex: 1;
          @media (max-width: 600px) {
            margin: 20px 10% 0 10%;
          }
          .logo {
            width: 80%;
            @media (max-width: 600px) {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .getStartedButt {
        width: auto;
        padding: 30px 82px;
        position: relative;
        display: block;
        text-decoration: none;
        overflow: hidden;
        margin-left: 148px;
        align-self: center;
        border: 2px solid #000;
        border-radius: 70px;
        margin-bottom: 24px;
        @media (max-width: 1000px) {
          margin-left: 0;
        }

        &:hover {
          .wave {
            top: -200px;
          }
        }

        .buttonText {
          position: relative;
          z-index: 2;
          color: white;
          font-size: 36px;
          text-align: center;
          @media (max-width: 1000px) {
            font-size: 24px;
          }
        }

        .wave {
          width: 400px;
          height: 320px;
          background-color: rgba(201, 39, 14, 1);
          box-shadow: inset 0 0 50px rgba(233, 69, 96, 0.5);
          position: absolute;
          left: -40px;
          top: -105px;
          transition: 0.4s;

          &::before,
          &::after {
            width: 170%;
            height: 200%;
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -75%);
          }

          &::before {
            border-radius: 45%;
            background-color: rgba(233, 69, 96, 1);
            animation: wave 5s linear infinite;
          }

          &::after {
            border-radius: 40%;
            background-color: rgba(233, 69, 96, 0.5);
            animation: wave 10s linear infinite;
          }
        }
      }
      .buttonContent {
        margin-left: 116px;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        border: 2px solid #2d87f6;
        background-color: #dddfed;
        border-radius: 36px;
        padding: 12px 36px;
        transition: 0.3s ease;
        &:hover {
          transform: scale(0.95);
          cursor: pointer;
        }
        @media (max-width: 1000px) {
          margin-left: 0;
        }
        .icon {
          margin-right: 10px;
        }
        .googleIcon {
          width: 18px;
          height: auto;
        }
        .googleText {
          color: var(--primary-color);
          margin: 5px;
          font-size: 14px;
        }
      }
    }
    .backgroundSection {
      position: relative;
      .backgroundWrapper {
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        margin-top: 96px;
        .background {
          margin-bottom: -100px;
          width: 100%;
        }
      }
      .foregroundWrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        .foreground {
          position: absolute;
          width: 90%;
          right: 0;
          bottom: -72px;
        }
      }
    }
  }
  .howTo {
    position: relative;
    margin: 64px auto;
    .title {
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      color: var(--secondary-color);
      text-transform: uppercase;
      text-align: center;
    }
    .subTitle {
      margin: 20px auto;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      text-transform: capitalize;
      color: #ffffff;
      max-width: 600px;
    }
    .cont {
      .Steps {
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 0 130px;
        width: 100%;
        height: auto;
        //margin: 0 130px;
        @media (max-width: 750px) {
          padding: 0 10px;
        }
      }
      .fingerLeft {
        position: absolute;
        top: 25%;
        left: 20%;
        width: 125px;
        height: 138px;
        background-image: url(../../assets/Onboarding/leftFinger.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @media (max-width: 750px) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: 550px) {
          width: 60px;
          height: 60px;
          top: 40%;
        }
      }
      .fingerRight {
        position: absolute;
        top: 38%;
        right: 10%;
        width: 125px;
        height: 138px;
        background-image: url(../../assets/Onboarding/rightFinger.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @media (max-width: 750px) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: 550px) {
          width: 60px;
          height: 60px;
          top: 50%;
        }
      }
      .hand {
        position: absolute;
        bottom: -150px;
        right: 27%;
        width: 25vw;
        height: 50%;
        background-image: url(../../assets/Onboarding/hand.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @media (max-width: 1150px) {
          bottom: -100px;
        }
      }
      .smileFace {
        position: absolute;
        bottom: -250px;
        right: 17%;
        width: 20vw;
        height: 50%;
        background-image: url(../../assets/Onboarding/smileyFace.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 2;
        @media (max-width: 1150px) {
          bottom: -150px;
        }
      }
    }
  }
  .whyKef {
    background: linear-gradient(183.82deg, #303041 -0.06%, #3a3a53 19.43%);
    padding-bottom: 90px;
    .whyTitle {
      padding: 60px;
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
      @media (max-width: 750px) {
        font-size: 26px;
        padding: 10px;
      }
    }
    .categories {
      display: grid;
      justify-content: center;
      text-align: center;
      margin: 0 500px;
      grid-template-rows: repeat(3, 1fr);
      gap: 30px;
      grid-auto-rows: minmax(100px, auto);
      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-template-rows: 6fr;
        margin: 0 auto;
        max-width: 300px;
      }
      @media (max-width: 520px) {
      }
      .category1 {
        position: relative;
        padding: 30px;
        grid-column: 1 / 4;
        grid-row: 1;
        background: #1a1e37;
        border-radius: 12px;
        min-height: 400px;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 1;
          position: relative;
          max-height: 300px;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          .bolder {
            font-weight: 700;
          }
        }
        .brickGame {
          position: absolute;
          bottom: 0;
          top: 0;
          left: 5%;
          right: 0;
          width: 200px;
          height: 100%;
          background-image: url(../../assets/Onboarding/brickGame.svg);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          z-index: 2;
          @media (max-width: 750px) {
            width: 150px;
          }
        }
        .joystick {
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          width: 300px;
          height: 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;

          @media (max-width: 750px) {
            width: 200px;
          }
        }
      }
      .category2 {
        padding: 30px;
        grid-column: 4 / 8;
        grid-row: 1;
        background: #1a1e37;
        border-radius: 12px;
        min-height: 400px;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 2;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          @media (max-width: 700px) {
            margin: 0 30px 30px 30px;
          }
          .bolder {
            font-weight: 700;
          }
        }
        .rotateWrapper {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 350px;
          height: 300px;
          .rotation {
            position: absolute;
            top: 27.7%;
            bottom: 0;
            left: 11.1%;
            width: 150px;
            height: auto;
            -webkit-animation: spin 2s linear infinite;
            -moz-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
          }
          .image {
            position: absolute;
            top: 40%;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .category3 {
        padding: 30px;
        grid-column: 1 / 3;
        grid-row: 2;
        background: #1a1e37;
        border-radius: 12px;
        min-height: 400px;
        min-width: 350px;
        position: relative;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 3;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          .bolder {
            font-weight: 700;
          }
        }
        .flag {
          position: absolute;
          top: 100px;
          left: 20px;
          width: 70%;
          height: auto;
        }
        .diamond {
          position: absolute;
          bottom: 50px;
          right: 20px;
          width: 50%;
          height: auto;
          // animation:  0.3s ease infinite;
          animation-name: zoom-in-zoom-out;
          animation-duration: 0.1s;
          animation-iteration-count: infinite;
        }
      }
      .category4 {
        padding: 30px;
        grid-column: 3 / 8;
        grid-row: 2;
        background: #1a1e37;
        border-radius: 12px;
        position: relative;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 4;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          @media (max-width: 700px) {
            margin: 0 30px 30px 30px;
          }
          .bolder {
            font-weight: 700;
          }
        }
        .eyeRotator {
          position: absolute;
          bottom: -2%;
          right: 16%;
          -webkit-animation: spin 2s linear infinite;
          -moz-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
          @media (min-width: 1000px) {
            bottom: 15px;
          }
        }
      }
      .category5 {
        padding: 30px;
        grid-column: 1 / 5;
        grid-row: 3;
        background: #1a1e37;
        border-radius: 12px;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 5;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          @media (max-width: 700px) {
            margin: 0 30px 30px 30px;
          }
          .bolder {
            font-weight: 700;
          }
        }
        .image {
          height: 55%;
        }
      }
      .category6 {
        padding: 30px;
        grid-column: 5 / 8;
        grid-row: 3;
        background: #1a1e37;
        border-radius: 12px;
        position: relative;
        @media (max-width: 1000px) {
          grid-column: 1;
          grid-row: 6;
        }
        .objTitle {
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          text-transform: capitalize;
          margin-bottom: 30px;
          @media (max-width: 700px) {
            margin: 0 30px 30px 30px;
          }
          .bolder {
            font-weight: 700;
          }
        }
        .image {
          width: 40%;
          height: auto;

          @media (min-width: 1000px) {
            width: 80%;
          }
        }
        .loopZoom {
          position: absolute;
          top: 30%;
          left: 0;
          bottom: 0;
          animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
          animation-duration: 4s; /* Change to speed up or slow down */
          animation-iteration-count: infinite;
        }
      }
    }
  }
  .faq {
    margin-bottom: 82px;
    .title {
      margin: 24px auto;
      font-weight: bold;
      font-size: 36px;
      line-height: 42px;
      text-transform: uppercase;
      text-align: center;
    }
    .questionContainer {
      margin: 20px 100px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 25px;
      padding: 12px;
      @media (max-width: 750px) {
        margin: 20px 30px;
      }
      .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .istitle {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          color: white;
          cursor: pointer;
          width: 100%;
          text-align: left;
          opacity: 0.7;
          font-weight: lighter;
          text-transform: capitalize;
        }
      }
      .description {
        padding: 18px auto;
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
        font-size: 16px;
        line-height: 21px;

        color: #ffffff;
        opacity: 0.8;
        &.active {
          transition: all 0.2s ease-out;
          margin-top: 15px;
          max-height: fit-content;
        }
      }
    }
  }
  .timerContainer {
    background: linear-gradient(183.82deg, #303041 -0.06%, #3a3a53 19.43%);
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 750px) {
        flex-direction: column;
      }
      .kefstart {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        margin-top: 98px;
        .logo {
          width: 80%;
          height: auto;
          @media (max-width: 750px) {
            margin: 20px auto;
            width: 60%;
          }
        }
        .getStartedButt {
          width: auto;
          margin-bottom: 18px;
          border: 2px solid #000000;
          padding: 8px 42px;
          position: relative;
          display: block;
          text-decoration: none;
          overflow: hidden;
          border-radius: 24px;

          &:hover {
            .wave {
              top: -120px;
            }
          }

          .buttonText {
            position: relative;
            z-index: 2;
            color: white;
            font-size: 15px;
            text-align: center;
          }

          .wave {
            width: 200px;
            height: 200px;
            background-color: rgba(201, 39, 14, 1);
            box-shadow: inset 0 0 50px rgba(233, 69, 96, 0.5);
            position: absolute;
            left: -20px;
            top: -85px;
            transition: 0.4s;

            &::before,
            &::after {
              width: 200%;
              height: 200%;
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, -75%);
            }

            &::before {
              border-radius: 45%;
              background-color: rgba(233, 69, 96, 1);
              animation: wave 5s linear infinite;
            }

            &::after {
              border-radius: 40%;
              background-color: rgba(233, 69, 96, 0.5);
              animation: wave 10s linear infinite;
            }
          }
        }
        .buttonContent {
          align-self: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px;
          border: 2px solid #2d87f6;
          background-color: #dddfed;
          border-radius: 36px;
          padding: 0 16px;
          transition: 0.3s ease;
          &:hover {
            transform: scale(0.95);
            cursor: pointer;
          }
          @media (max-width: 1000px) {
            margin-left: 0;
          }
          .icon {
            margin-right: 10px;
          }
          .googleIcon {
            width: 18px;
            height: auto;
          }
          .googleText {
            color: var(--primary-color);
            margin: 5px;
            font-size: 14px;
          }
        }
      }
      .bloomContainer {
        //  position: relative;
        .bloom {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.finger {
  display: none;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1.05, 1.05);
  }
  50% {
    transform: scale(1, 1);
  }
  75% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes wave {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

.backgroundContainer {
  position: absolute;
  height: 100%;
  align-items: center;
  .container {
    position: relative;
    height: 100%;
    .titleContainer {
      position: fixed;
      z-index: 5000;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 0;
      background-color: var(--primary-color);
      border: 1px solid rgba(0, 0, 0, 0.05);
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
      .kefLogo {
        display: none;
      }
      .closeButton {
        display: none;
      }
      .title {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 10px;
      }
      .subtitle {
        text-align: center;
        font-size: 14px;
        opacity: 0.5;
      }
    }
    .background {
      margin-top: 50px;
      padding-top: 36px;
      background-color: var(--black-color);
      //  background-image: url(/assets/login/background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      position: relative;
      position: fixed;
      overflow: scroll;
      width: 100vw;
      @media (min-width: 992px) {
        position: relative !important;
        overflow: hidden;
        width: auto;
        margin-top: 0;
      }
      .imageContainer {
        margin-left: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
        display: none;
      }
      .FreeBtcImg {
        display: none;
      }
      .btcText {
        display: none;
      }
      .content {
        position: relative;
        z-index: 1;
        padding: 0 20px;
        max-height: 100%;
        overflow-y: auto;
        @media (max-width: 992px) {
          margin-top: 24px;
        }
        .imageTitleContainer {
          text-align: center;
          padding: 30px 0;
          @media (max-width: 992px) {
            display: none; //This is for hiding kef logo!
          }
        }
        .inputContainer {
          margin-top: 32px;
          margin-bottom: 32px;
          @media (max-width: 992px) {
            margin-top: 24px;
            margin-bottom: 20px;
          }
        }
        .errorContainer {
          background-color: var(--secondary-color);
          margin-bottom: 24px;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 12px;
        }
        .buttonContainer {
          display: flex;
          margin-bottom: 32px;
        }
        .checkboxContainer {
          margin-bottom: 32px;
          .checkboxLabel {
            color: rgba(255, 255, 255, 0.3);
            .link {
              display: inline;
              color: var(--secondary-color);
              text-decoration: underline;
            }
          }
        }
        .disclaimerContainer {
          margin-bottom: 32px;
          .disclaimer {
            color: rgba(255, 255, 255, 0.3);
            margin-bottom: 82px;
            .link {
              display: inline;
              color: var(--secondary-color);
              text-decoration: underline;
            }
          }
        }
        .flexButtonContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 -12px;
          margin-bottom: 12px;

          .federatedContainer {
            display: flex;
            align-items: center;
            flex-direction: row;
            @media (max-width: 500px) {
              flex-direction: column;
            }
          }
          //  padding-bottom: 20px;
          @media (max-width: 500px) {
            flex-direction: column;
            margin: -12px;
            margin-bottom: 82px;
          }
          @media (max-width: 992px) {
            margin-bottom: 130px;
          }
          & > * {
            flex: 1;
            margin: 12px;
            text-align: center;
          }
          & > .noFlex {
            flex: initial;
          }
          .buttonContent {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            .icon {
              margin-right: 10px;
            }
          }
          .googleContainer {
            margin: 14px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px;
            border: 2px solid #2d87f6;
            background-color: #dddfed;
            border-radius: 36px;
            padding: 0 24px;
            @media (max-width: 500px) {
              padding: 4px;
            }
            &:hover {
              cursor: pointer;
            }
            .googleIcon {
              width: 18px;
              height: auto;
            }
            .googleText {
              color: var(--primary-color);
              margin: 5px;
              font-size: 14px;
            }
          }
        }
      }
      .bottomSpace {
        margin-bottom: 50px;
      }
      .lock {
        position: absolute;
        top: 0;
        left: 0;
      }
      .finger {
        position: absolute;
        top: 20px;
        right: 16px;
      }
      .cube {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

@media (min-width: 992px) {
  .backgroundContainer {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;

    justify-content: center;
    .container {
      background-color: var(--black-color);
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
      padding: 0;
      border: 1px solid rgba(255, 255, 255, 0.05);
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
      border-radius: 32px;
      max-width: calc(100% - 48px);
      height: auto;
      width: 60%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .titleContainer {
        //position:initial;
        border-radius: 32px 32px 0 0;
        position: relative;
        .kefLogo {
          display: initial;
          position: absolute;
          top: 15px;
          left: 15px;
        }
        .closeButton {
          display: initial;
          position: absolute;
          top: 15px;
          right: 15px;
        }
        /*  .popupHeader{
            position:absolute;
            padding: 0px 22px;
            display: flex;
            justify-content: space-between;
          }*/
        .title {
          font-size: 36px;
          margin: 0;
        }
        .subtitle {
          margin: 0;
          font-size: 18px;
        }
      }
    }
    .background {
      border-radius: 32px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .imageContainer {
        display: initial !important;
        position: relative;
        grid-column: 1 / 3;
        .FreeBtcImg {
          display: initial !important;
          width: 80%;
          height: auto;
          // margin:-10px -10px
        }
        .btcText {
          display: initial;
          white-space: nowrap;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          opacity: 0.5;
          margin: 0 10px;
        }
        .ammountText {
          position: absolute;
          bottom: 80px;
          font-size: 18px;
          opacity: 0.5;
          margin: 10px 10px;
        }
        .totAmmountContainer {
          position: absolute;
          bottom: -30px;
          left: -10px;
          padding: 25px 0;
          width: 100%;
          height: auto;
        }
      }
      .content {
        overflow-y: initial !important;
        grid-column: 3 / 6;
        .disclaimerContainer {
          font-size: 12px;
        }
        .imageTitleContainer {
          display: none;
        }
        .inputContainer {
          margin-bottom: 18px !important;
        }
        .flexButtonContainer {
          //  margin: 0 !important;
        }
      }
      .buttonContent {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        .icon {
          margin-right: 10px;
        }
        .buttonText {
          display: none;
        }
      }
    }
  }
  .lock {
    display: none;
  }
  .finger {
    display: none;
  }
  .cube {
    display: none;
  }
}

.backgroundContainer {
  height: 100%;

  align-items: center;
  position: fixed;
  margin-top: 50px;
  @media (min-width: 992px) {
    margin-top: 0px;
    position: absolute;
  }
  .wrapper {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 100vw;
      margin-top: 18px;
      padding-top: 36px;
      background-color: var(--black-color);
      //  background-image: url(/assets/login/background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      @media (min-width: 992px) {
        width: auto;
      }
      position: relative;
      .titleContainer {
        display: none;
      }
      .container {
        background-color: var(--black-color);

        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 23px;
        text-transform: capitalize;
        text-align: center;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;

        @media (min-width: 992px) {
          width: auto;
          min-width: 600px;
          max-width: 600px;
        }
        .description {
          margin: 10px 20px;
          padding-bottom: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.5;
        }
        .inputContainerButton {
          .inputContainer {
            margin-bottom: 32px;
            margin: 0 50px;
            @media (min-width: 992px) {
              margin: 0;
            }
          }
          .flexButtonContainer {
            display: flex;
            margin: 0 -12px;
            margin: 0 50px;
            @media (min-width: 992px) {
              margin: 0;
            }

            @media (max-width: 500px) {
              flex-direction: column;
              margin: -12px;
            }
            & > * {
              flex: 1;
              margin: 12px;
              text-align: center;
              margin: 30px 20px;
            }
            align-items: center;
          }
        }
        .errorContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
          .message {
            background-color: var(--secondary-color);
            margin: 0px 86px;
            border-radius: 6px;
            border: 1px solid rgba(0, 0, 0, 0.05);
            padding: 8px 24px;
            text-align: center;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .statisticsContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 30px 62px;
        text-align: center;
        .userReferred {
          background-color: rgba(255, 255, 255, 0.05);
          justify-content: center;
          align-items: center;
          margin: 10px;
          padding: 12px;
          border-radius: 15px;
          line-height: 17px;
          & > * {
            padding: 3px 0;
            font-size: 15px;
            font-weight: 700;
          }
          h5 {
            font-size: 15px;
            line-height: 17px;
            font-weight: 700;
            color: #ffffff;
            opacity: 0.5;
          }
        }
      }
    }
    .box {
      position: absolute;
      bottom: 50px;
      right: 0;
    }
    .zshape {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
@media (min-width: 992px) {
  .backgroundContainer {
    position: absolute;
    z-index: 2000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4) !important;
    display: flex;
    justify-content: center;
    .wrapper {
      max-width: calc(100% - 48px);
      height: auto;
      width: 50%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .content {
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        padding: 20px 20px;
        margin: 10px 20px;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        .titleContainer {
          display: flex;
          justify-content: space-between;
        }
      }
      .container {
        .description {
          margin: 10px 0 0 0 !important;
          padding-top: 12px !important;
          padding-bottom: 12px !important;
        }
        .inputContainerButton {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .inputContainer {
            width: 90%;
          }
        }
      }
    }
  }
  .box {
    display: none;
  }
  .zshape {
    display: none;
  }
}

.backgroundContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-blue);
    border-radius: 24px;
    margin: 0 64px;
    filter: drop-shadow(0px 4px 50px rgba(255, 255, 255, 0.2));
    .content {
      height: auto;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--primary-color);
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 6px 16px;
        .title {
          color: #fff;
          font-size: 20px;
        }
      }
      .offerContainer {
        padding: 12px 16px;
        @media (max-width: 645px) {
          text-align: center;
        }
        .headerContainer {
          display: flex;

          text-align: left;
          @media (max-width: 645px) {
            display: initial;
          }
          .icon {
            min-width: 96px;
            max-width: 96px;
            min-height: 96px;
            max-height: 96px;
            border-radius: 12px;
          }
          .explaination {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 24px;
            text-align: left;
            max-width: 450px;
            @media (max-width: 645px) {
              padding: 0;
              margin: 0;
              text-align: center;
              align-items: center;
            }
            .title {
              font-size: 22px;
            }
            .description {
              text-align: left;
              font-size: 16px;
              color: rgba(255, 255, 255, 0.4);
              @media (max-width: 645px) {
                text-align: center;
              }
            }
          }
        }
        .instructions {
          margin: 24px;
          text-align: left;
          font-size: 16px;
          color: rgba(255, 255, 255, 0.4);
          @media (max-width: 645px) {
            text-align: center;
          }
        }
      }
      .description {
        text-align: center;
        margin: 12px auto;
        font-size: 16px;
        .amount {
          color: var(--secondary-color);
        }
      }
      .buttonContainer {
        margin: 12px auto;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-color);
        padding: 12px 24px;

        border-radius: 24px;
        .buttonText {
          padding: 0 6px;
        }
      }
    }
  }
}

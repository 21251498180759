.wrapper {
  position: relative;
  .container {
    position: relative;
    background-color: var(--dark-blue);
    padding: 10px 0;
    display: flex;
    overflow-x: auto;
    border: 1px solid rgba(22, 33, 62, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
    .userWrapper {
      margin: 0 10px;
    }
    .activeUserWrapper {
      animation-name: example;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-play-state: running;
    }
  }
  .betaRelease {
    background-color: var(--dark-blue);
    padding: 12px auto;
    text-align: center;
    min-height: 35px;
    border: 1px solid rgba(22, 33, 62, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.4);
    .text {
      font-size: 18px;
      padding-top: 5px;
      padding-bottom: 5px;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .popup {
    position: absolute;
    top: 100%;
    background-color: #2d3751;
    padding: 12px 24px;
    margin-top: 8px;
    border-radius: 16px;
    &::after {
      position: absolute;
      top: -8px;
      left: 82px;
      transform: rotateZ(45deg);
      content: "";
      height: 16px;
      width: 16px;
      background: #2d3751;
    }
    .popupContainer {
      display: flex;
      justify-content: space-between;
      .titlesContainer {
        padding-right: 12px;
        .item {
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          opacity: 0.5;
          padding: 2px;
        }
      }
      .titlesData {
        .item {
          font-size: 14px;
          font-weight: 700;
          line-height: 14px;
          padding: 2px;
        }
      }
    }
  }
}

@keyframes example {
  0% {
    //transform: translateX(-100%);
    opacity: 0;
    width: 0;
  }
  10% {
    opacity: 0.1;
  }
  15% {
    opacity: 0.15;
  }
  20% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.25;
  }
  30% {
    opacity: 0.3;
  }
  35% {
    opacity: 0.35;
  }
  40% {
    opacity: 0.4;
  }
  45% {
    opacity: 0.45;
  }
  50% {
    opacity: 0.5;
    width: 50%;
  }
  55% {
    opacity: 0.55;
  }
  60% {
    opacity: 0.6;
  }
  65% {
    opacity: 0.65;
  }
  70% {
    opacity: 0.7;
  }
  75% {
    opacity: 0.75;
  }
  80% {
    opacity: 0.8;
  }
  85% {
    opacity: 0.85;
  }
  90% {
    opacity: 0.9;
  }
  95% {
    opacity: 0.95;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

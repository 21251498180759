.container {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    position: relative;
    .exit {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    background-color: var(--black-color);
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    border-radius: 32px;
    max-width: calc(100% - 48px);
    height: 90%;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @media (max-width: 975px) {
      width: 90%;
    }
    .qrCodePopupContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .loginPopup {
        .qrCodeContainer {
          padding-bottom: 12px;
        }
      }
    }
    .title {
      text-align: center;
      margin-top: 36px;
      font-size: 18px;
      color: var(--secondary-color);
    }
    .subTitle {
      text-align: center;
      margin-top: 36px;
      margin-bottom: 16px;
      font-size: 14px;
    }
    .offerName {
      text-align: center;
      font-size: 16px;
      color: var(--secondary-color);
    }
    .scanOffer {
      font-size: 14px;
      margin-bottom: 24px;
    }
    .image {
      border: 1px solid white;
      border-radius: 12px;
      margin: 12px auto;
      max-width: 84px;
      max-height: 84px;
    }
    .offerUrl {
      text-align: center;
      font-size: 14px;
      color: var(--secondary-color);
      &:hover {
        text-decoration: underline;
      }
      margin-bottom: 12px;
    }
    .emailContainer {
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      background-color: var(--primary-color);
      border-radius: 30px;
      border: 1px solid #000000;
      flex: 1;
      .newsLetter {
        padding: 10px 30px;
        background: #e94560;
        //border: 1px solid #000000;
        border: none;
        box-sizing: border-box;
        border-radius: 30px;
        font-weight: 700;
        @media (max-width: 975px) {
          // margin: 24px 0;
        }
      }
      .emailInput {
        padding: 10px;
        border: none;
        font-size: 14px;
        color: white;
        background-color: var(--primary-color);
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}

.backgroundContainer {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark-blue);
    border-radius: 24px;
    filter: drop-shadow(0px 4px 50px rgba(255, 255, 255, 0.2));
    .content {
      height: auto;
      width: 350px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--primary-color);
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 6px 16px;
        .title {
          color: #fff;
          font-size: 20px;
        }
      }
      .description {
        text-align: center;
        margin: 12px auto;
        font-size: 16px;
        .amount {
          color: var(--secondary-color);
        }
      }
      .buttonContainer {
        margin: 12px auto;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        .buttonText {
          padding: 12px 24px;
          font-size: 18px;
          background-color: var(--secondary-color);
          border-radius: 24px;
        }
      }
    }
  }
}

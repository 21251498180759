.contact {
  text-align: center;
  text-transform: capitalize;

  .contactContent {
    position: relative;
    z-index: 1;
    padding: 0 1.5rem;
    margin: 3rem 0;
    h2 {
      font-weight: 700;
      font-size: clamp(25px, 5vw, 30px);
      text-transform: uppercase;
      margin-bottom: 3rem;
    }
    p {
      font-weight: 400;
      a {
        color: #ff5986;
        display: inline-block;
      }
    }
  }
  .backgroundContainer {
    @media (max-width: 975px) {
      position: relative;
      padding-bottom: 40%;
      width: 100%;
      transform: translateX(-10%);
      transform: translateY(20%);
    }
    .background {
      position: absolute;
      top: 24px;
      left: 60%;
      transform: translateX(-50%);
      width: 70%;
      height: 71%;
      opacity: 0.3;
      @media (max-width: 975px) {
        width: 100%;
        height: 100%;
        // transform: translateX(-50%);
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .contact {
    text-align: left;
    .bcgSvg {
      right: 5%;
      img {
        transform: translateY(20%);
        fill: #7774ff;
      }
    }
  }
}

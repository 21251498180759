.allcontent {
  .container {
    position: relative;
    height: 100%;
    margin-top: 18px;
    @media (min-width: 992px) {
      margin-top: 0;
    }
    .background {
      margin-top: 50px;
      padding-top: 36px;
      background-color: var(--black-color);
      // background-image: url(/assets/login/background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      position: relative;
      position: fixed;
      @media (min-width: 992px) {
        margin-top: 0px;
        position: absolute;
      }
      .content {
        position: relative;
        z-index: 1;
        padding: 0 20px;
        max-height: 100%;
        overflow-y: auto;
        .closeButton {
          display: none;
        }
        .pPText {
          width: 226px;
          height: auto;
        }
        .pPDescription {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 10px;
          h4 {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            margin: 5px 0 0px 6px;
          }
          margin-bottom: 30px;
        }
        .metaMask {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 16px auto;
        }
        .ammountSelected {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          background-color: var(--black-color);
          grid-gap: 14px;
          min-width: 100px;
          margin-bottom: 30px;
          .select {
            border: 1px solid rgba(23, 155, 215, 0.1);
            box-sizing: border-box;
            box-shadow: 0px 0px 10px rgba(70, 187, 249, 0.3);
            border-radius: 30px;
            padding: 30px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              cursor: pointer;
            }
            .ammount {
              font-weight: bold;
              font-size: 22px;
              line-height: 25px;
              margin-left: 20px;
            }
          }
          .selected {
            background: #1a1a2e;
            border: 1px solid rgba(233, 69, 96, 0.1);
            box-shadow: 0px 0px 20px #e94560;
            border-radius: 30px;
          }
        }
        .imageTitleContainer {
          text-align: center;
          padding: 30px 0;
        }
        .inputContainer {
          display: flex;
          background-color: var(--black-color);
          padding: 8px;
          padding-left: 24px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
          border-radius: 30px;
          margin-bottom: 30px;
          .input {
            flex: 1;

            background-color: transparent;
            border: none;
            font-size: 16px;
            outline: none;
            border-radius: 30px;
            font-weight: bold;
            font-size: 12px;
            padding: 5px 10px;
            min-width: 19px;
            border-radius: 30px;
            border: none;
            &::placeholder {
              opacity: 0.7;
            }
          }
          .current {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0 12px;
          }
          .rate {
            margin: 0 5px;
          }
          .adressbutton {
            background-color: var(--secondary-color);
            background: #e87588;
            border-radius: 30px;
            font-weight: bold;
            font-size: 12px;
            padding: 12px 24px;
            border-radius: 30px;
            border: none;
          }
        }
        .addresstext {
          margin-bottom: 30px;
          font-weight: normal;
          font-size: 14px;
          color: var(--white-color);
          opacity: 0.5;
        }
        .buttonContainer {
          display: flex;
          margin-bottom: 32px;
        }

        .disclaimerContainer {
          margin-bottom: 32px;
          .disclaimer {
            color: rgba(255, 255, 255, 0.3);
            .link {
              display: inline;
              color: var(--secondary-color);
              text-decoration: underline;
            }
          }
        }
        .flexButtonContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          .calculations {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            visibility: hidden; // Remove this to show fee and coinprice

            .fee {
              display: flex;

              flex-direction: row;
              justify-content: space-between;
              padding: 6px 10px;
              font-weight: normal;
              font-size: 15px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
            }
            .priceCoin {
              display: flex;
              gap: 70px;
              flex-direction: row;
              justify-content: space-between;
              padding: 6px 10px;
              font-size: 15px;
              line-height: 17px;
              text-transform: capitalize;
              color: #ffffff;
            }
          }
        }
        .errorContainer {
          background-color: var(--secondary-color);
          margin-bottom: 24px;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 12px;
        }
        //IFRAME CSS
        .iframe {
          margin-top: 12px;
          width: 100%;
          height: 70vh;
        }
      }
      .cloudL {
        position: absolute;
        bottom: 10px;
        left: 0;
      }
      .cloudR {
        position: absolute;
        bottom: 70px;
        right: 0;
      }
      .cloudBot {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
}

@media (min-width: 992px) {
  .allcontent {
    .container {
      position: absolute;
      z-index: 2000;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;

      .background {
        background-color: var(--black-color);
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        max-width: calc(100% - 48px);
        height: auto;
        width: 50%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 20px;
        .content {
          .closeButton {
            display: initial;
            float: right;
          }
          .adressContainer {
            padding: 20px 10px;
            margin: 20px auto;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 32px;

            .inputContainer {
              margin: 0;
              background-color: transparent;
              border: none;
              box-shadow: none;
              justify-content: space-between !important;
              align-items: center;
            }
            .input {
              background-color: #252941;
              border: 1px solid rgba(0, 0, 0, 0.05);
              box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
              justify-content: space-around;
              flex: 0;
              width: 70%;
              flex-basis: auto;
              padding: 12px 18px;
              margin-right: 15px;
            }
          }
        }
        .ammountSelected {
          display: flex !important;
          justify-content: space-around;
          flex-wrap: wrap;
          .select {
            padding: 30px 30px !important;
          }
        }
      }
      .cloudL {
        display: none;
      }
      .cloudR {
        display: none;
      }
      .cloudBot {
        display: none;
      }
    }
  }
}

.loadingWrapper {
  padding-top: 120px;
  padding-bottom: 120px;
}
.backgroundContainer {
  margin-top: 64px;
  margin-bottom: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;
  .otherBlogsContainer {
    width: 300px;

    min-width: 300px;

    &:hover {
      cursor: pointer;
    }
    &:hover .blogTitle {
      text-decoration: underline;
    }
    .imageWrapper {
      width: 300px;
      height: 200px;

      .blogImage {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        border: 1px solid #000000;
        box-sizing: border-box;
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
        border-radius: 30px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--secondary-color);
        font-size: 84px;
        font-weight: 1000;

        &:hover {
          opacity: 0.7;
        }
      }
    }
    .descriptionContainer {
      padding-left: 12px;
      .dateContainer {
        display: flex;
        justify-content: center;
        min-width: 200px;
        flex-wrap: wrap;

        .timeDate {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.5;
          padding-right: 12px;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        .timeline {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-transform: capitalize;
          color: #ffffff;
          opacity: 0.5;
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .blogTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-transform: uppercase;
        color: #ffe483;
        margin: 20px auto;
        margin-left: 8px;
        @media (max-width: 768px) {
          text-align: inherit;
          font-size: 14px;
        }
      }
      .blogSubtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        text-transform: capitalize;
        color: #fafafa;
        margin-left: 8px;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

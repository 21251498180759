.container {
  height: 12px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  overflow: hidden;
  .indicator {
    background: var(--pink-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    height: 100%;
    transition: 0.3s ease;
    width: 0%;
  }
}

.faq {
  text-align: center;
  margin-bottom: 120px;
  h2 {
    text-transform: uppercase;
    font-size: clamp(15px, 5vw, 25px);
    margin: 2rem 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 48px;
  }
  .questions {
    padding: 0 1.5rem;
    margin-bottom: 3rem;
    .question {
      margin: 2rem 0;
      h3 {
        font-family: "Whitney";
        font-style: normal;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
      p {
        color: #8c8e9b;
        margin: 16px auto 56px auto;
        font-family: "Whitney";
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    a {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: clamp(250px, 70vw, 350px);
      background-color: #e87588;
      font-size: clamp(14px, 4vw, 20px);
      font-weight: 700;
      border: none;
      border-radius: 50px;
      padding: 0.5em 1em;
      text-align: center;
      transition: 200ms ease-in-out background-color;
      &:hover {
        background-color: #d32e49;
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }

  .topLeftMouth {
    position: absolute;
  }
  .bottomRightMouth {
    position: absolute;
    right: 0;
    bottom: 47vh;
    z-index: 1000;
    width: clamp(80px, 10vw, 150px);
    @media (max-width: 992px) {
      bottom: 75vh;
    }
  }
  .bottomRightMouth img {
    width: 100%;
  }
}

@media only screen and (min-width: 900px) {
  .faq {
    text-align: left;
    h2 {
      color: #e87588;
    }
    .questions a {
      position: relative;
      transform: translateX(0);
      left: 0;
    }
    .topLeftMouth {
      display: none;
    }
  }
}

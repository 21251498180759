.privacyPolicy {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  .header {
    padding: 0 1.5rem;
    margin-top: 3rem;
    .title {
      text-transform: uppercase;
      h2 {
        font-weight: 600;
      }
      h3 {
        color: rgba($color: #fff, $alpha: 0.5);
        margin: 1rem 0;
        font-weight: 400;
      }
    }
    // .lang {
    //     display: flex;
    //     flex-direction: column-reverse;
    //     align-items: center;
    //     position: relative;
    //     text-align: center;
    //     .selected {
    //         background-color: #2D3751;
    //         filter: brightness(130%);
    //         width: 100%;
    //         max-width: 400px;
    //         min-width: 300px;
    //         margin-bottom: .5rem;
    //         padding: .8em 0;
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-evenly;
    //         border-radius: 50px;
    //         position: relative;
    //         cursor: pointer;
    //         user-select: none;
    //         font-weight: 600;
    //         font-size: 1.1rem;
    //         svg:first-of-type {
    //             width: 25px;
    //             height: 25px;
    //         }
    //         svg:last-of-type {
    //             width: 15px;
    //             height: 15px;
    //             transition: 200ms ease-in-out transform;
    //         }
    //     }
    //     .optionsContainer {
    //         background-color: #2D3751;
    //         filter: brightness(130%);
    //         width: 100%;
    //         max-width: 400px;
    //         border-radius: 8px;
    //         overflow: hidden;
    //         max-height: 0;
    //         opacity: 0;
    //         transition: 200ms ease-in-out max-height, 200ms ease-in-out opacity;
    //         .option {
    //             cursor: pointer;
    //             &:hover {
    //                 background-color: #2D3751;
    //                 filter: brightness(80%);
    //             }
    //             label {
    //                 cursor: pointer;
    //                 padding: 1em 0;
    //                 font-weight: 300;
    //             }
    //             input {
    //                 display: none;
    //             }
    //         }
    //         &::-webkit-scrollbar {
    //             width: 5px;
    //             background-color: #1a1a2e;
    //             border-radius: 0 10px 10px 0;
    //         }
    //         &::-webkit-scrollbar-thumb {
    //             background-color: #1B294C;
    //             border-radius: 0 10px 10px 0;
    //         }
    //     }
    //     .active {
    //         max-height: 240px;
    //         opacity: 1;
    //         overflow-y: scroll;
    //     }
    //     .optionsContainer.active + .selected svg:last-of-type {
    //         transform: rotate(180deg);
    //     }
    // }
  }
  .info {
    text-transform: capitalize;
    padding: 0 1.5rem;
    .single {
      margin: 2rem 0;
      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
      p {
        color: #8c8e9b;
        margin-top: 1.5rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  h3 {
    font-size: clamp(15px, 6vw, 20px);
  }

  .bcgSvg {
    svg,
    img {
      opacity: 0.3;
      z-index: -1;
      pointer-events: none;
      user-select: none;
    }
    .topLeftEye {
      position: absolute;
    }
    .topRightBell {
      position: absolute;
      right: 0;
      top: 100px;
    }
    .bottomMarker {
      position: absolute;
      bottom: 340px;
      right: 50%;
      z-index: 2;
      transform: translateX(100%);
      width: 100px;
      img {
        opacity: 0.3;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .privacyPolicy {
    text-align: left;
    .header {
      padding: 0 5rem 0 1.5rem;
      .title {
        margin-top: 0;
        h2 {
          color: #e87588;
        }
      }
    }
    .info {
      padding: 0 5rem 0 1.5rem;
    }
    .bcgSvg {
      .topLeftEye {
        left: 50%;
        svg {
          width: 250px;
          height: 150px;
        }
      }
      .topRightBell {
        top: 400px;
        overflow-x: hidden;
        svg {
          width: 250px;
          height: 250px;
          transform: translateX(30%);
        }
      }
      .bottomMarker {
        right: 0;
        width: 150px;
        height: 150px;
        transform: translateX(0);
      }
    }
  }
}

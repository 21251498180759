.subContainer {
  margin: 30px auto;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    margin: 10px auto;
  }
  .subTitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    color: #ffffff;
    opacity: 0.5;
    padding-bottom: 10px;
  }
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  .paymentLink {
    position: relative;
    background: #1a1a2e;
    border: 1px solid rgba(23, 155, 215, 0.1);
    box-sizing: border-box;

    border-radius: 30px;
    padding: 42px 30px;
    margin: 24px 100px;

    .linkImage {
      width: 226px;
      height: 50px;
    }
    .linkImageBlur {
      filter: blur(4px);
      width: 226px;
      height: 50px;
    }
    .lighting {
      position: absolute;
      bottom: -15px;
      right: -15px;
      width: 48px;
      height: auto;
    }
  }
}
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  .construction {
    position: absolute;
    width: 99%;
    animation: shake 0.32s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    left: 0;
    top: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container {
      border: 1px solid rgba(52, 93, 157, 0.8);
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(52, 93, 157, 0.6);
      background-color: rgb(52, 93, 157);
      margin: 20px;
      padding: 20px;
      border-radius: 30px;
      z-index: 1;
      .title {
        text-align: center;
        font-size: 24px;
        margin: 15px;
      }
      .message {
        text-align: center;
        font-size: 18px;
        margin: 15px;
      }
    }
  }
}

@media (min-width: 992px) {
  .allcontent {
    margin-bottom: 300px;
    .subContainer {
      text-align: left;
      margin-left: 48px;
      .title {
        font-size: 22px;
      }
      .subTitle {
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
    .paymentContainer {
      flex-direction: initial;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-evenly;
      .paymentLink {
        margin: 30px !important;
      }
    }
  }
}

.container {
  .tabContainer {
    background: var(--dark-blue);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px 30px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 128px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 12px;
    padding: 0 24px;
    .tab {
      border: none;
      background-color: transparent;
      font-size: 18px;
      font-weight: 700;
      padding: 14px;
      transition: 0.3s ease;
      &.active {
        color: var(--secondary-color);
      }
    }
  }
  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px 0;
  }
  .inputContainer {
    margin-bottom: 64px;
  }
  .tableContainer {
    position: relative;
    width: calc(100% + 128px);
    transform: translateX(-50%);
    left: 50%;
    display: flex;
    @media (min-width: 992px) {
      display: none;
    }
    .tableHead {
      padding: 20px 40px;
      padding-right: 12px;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
      border-radius: 0px 30px 30px 0px;
      .label {
        margin: 20px 0;
        font-weight: 15px;
        font-weight: 700;
        flex: 1;
        text-align: center;
      }
    }
    .tableContent {
      flex: 1;
      display: flex;
      overflow-y: auto;
      &::-webkit-scrollbar {
        // width: 0px;
        // background: transparent; /* make scrollbar transparent */
        height: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 24px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
        margin: 12px;
      }
      padding: 20px 10px;
      .tableItem {
        min-width: 140px;
        background-color: rgba(255, 255, 255, 0.1);
        margin: 0 6px;
        padding: 0 12px;
        border-radius: 15px;
        .value {
          margin: 20px 0;
          font-weight: 15px;
          text-transform: capitalize;
        }
      }
      .noEarningsContainer {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        margin-top: 64px;
        display: flex;
        flex-direction: column;

        align-items: center;
        margin-right: -164px;
        .imageContainer {
          display: flex;
          justify-content: center;

          .cactus {
            width: 84px;
            height: auto;
          }
        }
        .countryDescription {
          text-align: center;
          margin: 12px 200px;
          float: right;
          opacity: 0.8;
        }
      }
    }
  }
  .earningTable {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 24px;
    padding: 8px 1px;
    @media (max-width: 992px) {
      display: none;
    }
    .tableWrapper {
      width: 100%;
      height: 400px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        // width: 0px;
        // background: transparent; /* make scrollbar transparent */
        width: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: var(--secondary-color);
        border-radius: 24px;
      }
      &::-webkit-scrollbar-track {
        background: white;
        margin: 12px;
        border-radius: 24px;
      }
      @media (max-width: 992px) {
        display: none;
      }

      .desktopTable {
        width: 100%;
        position: relative;
        .tableTitles {
          width: 100vw;
          text-align: center;
          position: relative;
          .label {
            font-size: 20px;
            font-weight: 700;
            padding-bottom: 24px;
            position: sticky;
            top: 0;
            z-index: 2;
            background: linear-gradient(var(--black-color), 90%, transparent);
            padding-top: 8px;
          }
          .value {
            border-collapse: collapse;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            font-size: 16px;
            padding: 12px 0;
            font-weight: 400;
            text-transform: capitalize;
            position: relative;
            .currentDate {
              position: absolute;
              top: 80%;
              left: 20%;
              background-color: #2d3751;
              padding: 12px 24px;
              margin-top: 8px;
              border-radius: 16px;
              z-index: 10;

              &::after {
                position: absolute;
                top: -8px;
                left: 82px;
                transform: rotateZ(45deg);
                content: "";
                height: 16px;
                width: 16px;
                background: #2d3751;
              }
              &.noActive {
                display: none;
              }
            }
          }
          &:nth-child(even) {
            background: var(--dark-blue);
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
        }
        .noEarningsContainer {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin-top: 64px;
          .imageContainer {
            display: flex;
            justify-content: center;

            .cactus {
              width: 84px;
              height: auto;
            }
          }

          .countryDescription {
            text-align: center;
            margin: 12px 36px;
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
    .tabContainer {
      justify-content: flex-start;
    }
    .buttonsContainer {
      justify-content: flex-start;
      align-items: center;

      & > * {
        margin: 14px !important;
      }
    }
  }
  .inputContainer {
    width: 270px;
  }
  .tableContainer {
    flex-direction: column;
    .tableHead {
      display: flex;
      justify-content: space-around;
      padding: 0 40px !important;
      padding-right: 65px !important;
      box-shadow: none !important;
    }
  }

  .tableContent {
    flex-direction: column;
    padding: 0 !important;

    .tableItem {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 300px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      background-color: transparent !important;
      border-radius: 0 !important;
      .value {
        margin: 10px auto !important;
      }
    }
  }
}

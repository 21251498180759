.backgroundContainer {
  margin: 0;
  padding: 0;
  background-color: var(--dark-blue);
  .headerContainer {
    background-color: var(--primary-color);
    height: 22vw;
    @media (max-width: 800px) {
      height: 70vw;
    }
    .header {
      // padding: 12px 36px;
      padding-left: 36px;
      position: absolute;
      top: -50px;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      @media (max-width: 1000px) {
        top: -10px;
      }
      @media (max-width: 800px) {
        display: none;
      }
    }
    .welcomeContainer {
      display: none;
      padding: 50px;
      @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .welcome {
        font-size: 36px;
        font-weight: 700;
        text-align: center;
      }
      .kef {
        width: 100%;
      }
    }
  }
  .sectionContainer {
    margin: 48px 48px;
    margin-right: 35vw;
    margin-left: 64px;
    padding-bottom: 48px;
    @media (max-width: 1000px) {
      margin-right: 64px;
    }
    @media (max-width: 675px) {
      margin-right: 12px;
      margin-left: 12px;
    }
    .sectionTitle {
      font-weight: 700;
      font-size: 36px;
      margin: 36px auto;
    }
    .sectionSubTitle {
      font-weight: 700;
      font-size: 26px;
      margin: 28px auto;
      color: #fffeaa;
      @media (max-width: 875px) {
        font-size: 22px;
      }
    }
    .sectionParagraph {
      margin: 24px auto;
      font-size: 18px;
      opacity: 0.7;
      font-weight: 400;
      @media (max-width: 875px) {
        font-size: 16px;
      }
    }
  }
  .ourProductSection {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    .ourProductTitle {
      opacity: 1;
      transform: translateY(100px);
      font-weight: 700;
      font-size: 48px;
      text-align: center;
    }
    .face {
      position: absolute;
      left: 100px;
      //right: 0;
      top: 0;
      width: 20%;
      height: auto;
      z-index: -1;
      opacity: 0.8;
      @media (max-width: 675px) {
        width: 40%;
        left: 10px;
      }
    }
    .popcorn {
      position: absolute;
      // left: 0;
      right: 0;
      top: 50px;
      width: 30%;
      height: auto;
      z-index: -1;
      opacity: 0.8;
      @media (max-width: 675px) {
        width: 50%;
      }
    }
    .cardsContainer {
      margin: 200px 0px;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;
      z-index: 2;
      .card {
        margin: 36px 0;
        padding: 12px 12px;
        background: #1a1a2e;
        border: 1px solid rgba(242, 148, 0, 0.1);
        box-shadow: 0px 0px 20px rgba(242, 148, 0, 0.3);
        border-radius: 30px;
        max-width: 250px;
        min-width: 150px;
        height: auto;
      }
      .cardTitle {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 12px;
      }
      .cardDescription {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 24px;
        opacity: 0.7;
      }
    }
  }
  .roadmapContainer {
    text-align: center;
    .roadMapTitle {
      font-weight: 700;
      font-size: 36px;
      margin-bottom: 56px;
      text-align: center;
    }
    .roadMapSwiper {
      .roadMap {
        display: flex;
        flex-direction: column;

        align-items: center;
        position: relative;
        .title {
          font-size: 28px;
          color: #fffeaa;
        }
        .bigCircle {
          margin: 36px;
          background: #fffeaa;
          border: 22px solid #ffba68;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
          border-radius: 143px;
          width: 140px;
          height: 140px;
          display: flex;
          justify-content: center;
          align-items: center;
          .flag {
            width: 70%;
            height: auto;
          }
        }
        .roadProgress {
          position: absolute;
          width: 100%;
          height: 15px;
          top: 130px;
          background: #fffeaa;
          transform: translateX(100px);
          z-index: -1;
          @media (max-width: 1100px) {
            display: none;
          }
        }
        .roadProgressCurrent {
          position: absolute;
          width: 100%;
          height: 15px;
          top: 130px;
          background: #9af3ff;
          transform: translateX(100px);
          z-index: -1;
          .fill {
            width: 60%;
            height: 15px;
            background: #fffeaa;
          }
          @media (max-width: 1100px) {
            display: none;
          }
        }
        .itemsContainer {
          .item {
            text-align: left;
            line-height: 26px;
          }
        }
        .currCircle {
          margin: 36px;
          background: #ffffff;
          border: 22px solid #9af3ff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
          border-radius: 143px;
          width: 170px;
          height: 170px;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateY(-10px);
        }
      }
    }
  }
  .customerAcquisition {
    margin-top: 100px;

    .title {
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
    }

    .subTitle {
      margin-top: 24px;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 24px;
    }
    .currentChannelsWrapper {
      background-color: #665ec2;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding-top: 24px;
      padding-bottom: 24px;
      .currentChannels {
        font-size: 28px;
        text-align: center;
        padding-bottom: 16px;
        font-weight: 700;
        opacity: 0.4;
      }
      .table {
        width: 100%;
        border-collapse: collapse;
        display: flex;
        flex-direction: column;
        align-items: stretch; /* Center the table horizontally */
        /* ... other styles ... */

        .tableRow {
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;
          padding-left: 12px;
          padding-right: 12px;
          /* ... other styles ... */
        }

        .tableCell {
          /* Use consistent capitalization for the class name */
          min-width: 33%;
          font-size: 24px;
          opacity: 0.4;
          font-weight: 700;
          text-align: center; /* Center the content within the cell */
        }
      }
    }
  }
  .financialProjections {
    padding-bottom: 24px;
    margin-top: 140px;
    .currentChannels {
      font-size: 28px;
      text-align: center;
      padding-bottom: 16px;
      font-weight: 700;
      opacity: 0.4;
    }
    .title {
      text-align: center;
      font-size: 42px;
      font-style: normal;
      font-weight: 700;
    }

    .subTitle {
      margin-top: 24px;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 24px;
    }
    .currentChannelsWrapper {
      background-color: #665ec2;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
      .currentChannels {
        font-size: 28px;
        text-align: center;
        padding-bottom: 16px;
        font-weight: 700;
        opacity: 0.4;
      }
      .tableWrapper {
        display: "flex";
        width: 100%;
        justify-content: center;
        align-items: center;
        .table {
          width: 100%;
          border-collapse: collapse;
          display: flex;
          flex-direction: column;
          align-items: stretch; /* Center the table horizontally */
          /* ... other styles ... */

          .tableRow {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 12px;
            padding-left: 12px;
            padding-right: 12px;
            /* ... other styles ... */
          }

          .tableCell {
            /* Use consistent capitalization for the class name */
            min-width: 22%;
            font-size: 24px;
            opacity: 0.4;
            font-weight: 700;
            text-align: left; /* Center the content within the cell */
          }
          .tableCellCenter {
            /* Use consistent capitalization for the class name */
            min-width: 22%;
            font-size: 24px;
            opacity: 0.4;
            font-weight: 700;
            text-align: center; /* Center the content within the cell */
          }
          .tableCellRight {
            /* Use consistent capitalization for the class name */
            min-width: 22%;
            font-size: 24px;
            opacity: 0.4;
            font-weight: 700;
            text-align: right; /* Center the content within the cell */
          }
        }
      }
    }
  }
  .companiesSection {
    margin-top: 64px;
    .gtm {
      font-size: 54px;
      text-align: center;
    }
    .companiesTitle {
      padding: 32px 0;
      font-weight: 700;
      font-size: 28px;
      line-height: 46px;
      text-align: center;
      color: #ff5986;
    }
    .imageContainer {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 36px 24px;
      @media (max-width: 900px) {
        flex-direction: column;
        padding: 0;
      }
      .gaming {
        padding: 12px 24px;
        width: 40%;
        height: auto;
        @media (max-width: 900px) {
          width: 90%;
          padding: 0;
        }
      }
      .middleContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: 900px) {
        }
        .middle {
          width: 100%;
          height: auto;
          padding: 12px 0;
        }
      }
    }
  }
  .challengeContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 48px;
    margin-right: 40%;
    @media (max-width: 900px) {
      margin: 48px;
    }
    .challengeTitle {
      font-weight: 700;
      font-size: 38px;
      margin-bottom: 18px;
      @media (max-width: 900px) {
        font-size: 32px;
      }
    }
    .challengeSubTitle {
      font-weight: 500;
      font-size: 18px;
      opacity: 0.9;
      padding: 12px 0;
      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
    .challengeList {
      margin-left: 24px;
      margin-bottom: 4px;
    }
  }
  .userContainer {
    margin-top: 84px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    @media (max-width: 760px) {
      flex-direction: column;
    }
    .teamContainer {
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 48px;
      }
      .user {
        background: #1a1a2e;
        border: 1px solid rgba(242, 148, 0, 0.1);
        box-shadow: 0px 0px 20px rgba(242, 148, 0, 0.3);
        border-radius: 30px;
        margin: 64px 0;
        max-width: 320px;
        height: auto;
        padding: 22px;
        .userDescription {
          display: flex;
          justify-content: space-between;
          .container {
            .userName {
              font-weight: 700;
              font-size: 24px;
              color: #fffeaa;
            }
            .userRank {
              font-weight: 700;
              font-size: 24px;
            }
          }
        }

        .userParagraph {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .advisorContainer {
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 48px;
      }
      .user {
        background: #1a1a2e;
        border: 1px solid rgba(242, 148, 0, 0.1);
        box-shadow: 0px 0px 20px rgba(242, 148, 0, 0.3);
        border-radius: 30px;
        margin: 64px 0;
        max-width: 320px;
        height: auto;
        padding: 22px;
        .userDescription {
          display: flex;
          justify-content: space-between;
          .container {
            .userName {
              font-weight: 700;
              font-size: 24px;
              color: #fffeaa;
            }
            .userName2 {
              font-weight: 700;
              font-size: 20px;
              color: #fffeaa;
            }
            .userRank {
              font-weight: 700;
              font-size: 24px;
            }
            .userRank2 {
              font-weight: 700;
              font-size: 20px;
            }
          }
        }
        .userParagraph {
          margin-top: 8px;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .rubik {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 380px;
      height: auto;
      opacity: 0.8;
      @media (max-width: 900px) {
        width: 200px;
      }
    }
    .planet {
      z-index: 1;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 760px;
      height: auto;
      opacity: 0.8;
      @media (max-width: 900px) {
        width: 400px;
      }
    }
  }
  .contactSection {
    position: relative;
    z-index: 1;
    padding: 64px 0;

    .contactContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 200px;
      @media (max-width: 900px) {
        margin: 0 12px;
        align-items: center;
      }
      .power {
        font-weight: 700;
        font-size: 48px;
        z-index: 1;
        text-align: right;
        white-space: wrap;
        @media (max-width: 900px) {
          font-size: 28px;
          text-align: center;
        }
      }
    }
    .centering {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .thankYou {
        font-size: 90px;
        font-weight: 700;
        text-transform: uppercase;
        @media (max-width: 900px) {
          margin-top: 12px;
          font-size: 48px;
        }
      }
      .contact {
        background: #e94560;
        border-radius: 94px;
        padding: 28px 80px;
        font-size: 32px;
        font-weight: 700;
        @media (max-width: 900px) {
          margin-top: 36px;
          padding: 16px 48px;
          font-size: 26px;
        }
        &:hover {
          background: #d51e3c;
        }
      }
    }
    .crystals {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 400px;
      height: auto;
      z-index: -1;
      opacity: 0.8;
      @media (max-width: 900px) {
        width: 200px;
      }
    }
  }
}

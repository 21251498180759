.container {
  .graph {
    overflow: visible;
    .textsContainer {
      fill: rgba(255, 255, 255, 0.5);
    }
    .gridContainer {
      fill: transparent;
      stroke: rgba(255, 255, 255, 0.5);
      pointer-events: none;
      .frame {
        pointer-events: initial;
      }
      .pointsContainer {
        stroke: var(--secondary-color);
        stroke-width: 2;
      }
      .foreignObject {
        transition: 0.3s ease;
        .detailsContainer {
          background-color: #313143;
          height: 60px;
          width: 100%;
          padding: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 32px;
          position: relative;
          &::after {
            position: absolute;
            height: 20px;
            width: 20px;
            background-color: #313143;
            content: "";
            transform-origin: center;
            transform: rotate(45deg);
            left: 60;
            top: 50px;
            z-index: -1;
          }
          .date {
            font-size: 13px;
            opacity: 0.5;
          }
          .value {
            font-size: 13px;
            font-weight: 700;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.backgroundContainer {
  position: relative;
  .featuredOffersContainer {
    animation: slideInFromRight 1s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin: 36px auto;
    @media (max-width: 992px) {
      justify-content: center;
      flex-wrap: wrap;
    }
    .featuredOfferWrapper {
      position: relative;
      .featuredOffer {
        margin: 8px;
        width: 100px;
        height: auto;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 5px #4baeff4d;
        &:hover {
          cursor: pointer;
        }
      }
      .Infos {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 4px;
        margin: 8px;
        text-align: center;
        width: 100px;
        height: 35%;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        background-color: rgba(101, 100, 100, 0.6);
        border-top: 1px solid rgba(101, 100, 100, 0.8);
        .coins {
          color: var(--primary-color);
          -webkit-text-stroke: 1px #f8f8f8;
          text-shadow: 0px 1px 1px #23430c;
          word-spacing: 40px;
          font-weight: 900;
          font-size: 16px;
        }
        .icon {
          width: 16px;
          height: auto;
          margin-left: 8px;
        }
        .dollarSign {
          fill: #f1b31c;
          width: 18px;
          height: auto;
          font-size: 20px;
          margin-right: 6px;
        }
      }
    }
    .blur {
      filter: blur(6px);
    }
  }
  .container {
    padding: 30px 20px;
    padding-bottom: 50px;
    text-align: center;
    .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 14px;
      opacity: 0.5;
      margin-bottom: 28px;
    }
    .titleBlog {
      margin-top: 84px;
      font-weight: bold;
      font-size: 22px;
      @media (max-width: 738px) {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 84px;
      }
    }
    .blogContainer {
      display: flex;
      flex-wrap: wrap;
      .otherBlogsContainer {
        max-width: 350px;
        padding: 24px;
        display: flex;
        flex-direction: column;

        align-items: center;
        @media (max-width: 738px) {
          flex-direction: row;
          max-width: 100%;
          min-width: 100vw;
          align-items: center;
          justify-content: flex-start;
          padding: 8px 24px;
        }
        &:hover {
          cursor: pointer;
        }
        &:hover .blogTitle {
          text-decoration: underline;
        }
        .imageWrapper {
          width: 300px;
          height: 200px;
          @media (max-width: 738px) {
            min-width: 100px;
            min-height: 100px;
            max-width: 100px;
            max-height: 100px;
          }
          .blogImage {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            border: 1px solid #000000;
            box-sizing: border-box;
            box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
            border-radius: 30px;
            object-fit: cover;

            &:hover {
              opacity: 0.7;
            }
          }
        }
        .descriptionContainer {
          padding-left: 12px;
          .dateContainer {
            display: flex;
            justify-content: center;
            @media (max-width: 738px) {
              padding-left: 6px;
              justify-content: flex-start;
            }
            .timeDate {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
              padding-right: 12px;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
            .timeline {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
              @media (max-width: 768px) {
                font-size: 12px;
              }
            }
          }

          .blogTitle {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-transform: uppercase;
            color: #ffe483;
            margin: 20px auto;
            margin-left: 8px;
            @media (max-width: 768px) {
              text-align: inherit;
              font-size: 14px;
              margin-right: 24px;
            }
          }
          .blogSubtitle {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            text-transform: capitalize;
            color: #fafafa;
            margin-left: 8px;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }
      }
    }
    .campainsContainer {
      animation: slideInFromLeft 0.5s;
      margin: -20px 0;
      margin-top: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .loading {
        margin-left: auto;
        margin-right: auto;
      }
      .campaignContainer {
        display: flex;
        align-items: center;
        background-color: var(--black-color);
        height: 180px;
        margin: 20px 0;
        border: 1px solid rgba(70, 187, 249, 0.1);
        box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.3);
        border-radius: 30px;
        max-width: 340px;
        width: 100%;
        position: relative;
        &:hover {
          cursor: pointer;
        }
        .captchaWrapper {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          text-align: center;
        }
        .plusPercentage {
          position: absolute;
          top: 12px;
          right: 12px;
          background-color: var(--secondary-color);

          font-size: 18px;
          font-weight: 700;
          padding: 3px 6px;
          border-radius: 8px;
          z-index: 1;
        }
        .campaignImage {
          box-sizing: border-box;
          width: 100%;
          padding: 5px 24px;
        }
        .campaignImageDisabled {
          backdrop-filter: blur(5px);
        }
        .firstCampaignImage {
          box-sizing: border-box;
          width: 100%;
          padding: 5px 24px;
          animation: shake 0.32s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation-delay: 0.8s;
        }
      }
    }
    .errorContainer {
      margin: 40px 30%;
      width: 40;
      background-color: var(--secondary-color);
      text-align: center;
      margin-bottom: 24px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      padding: 12px;
    }
  }
  .arcade {
    display: none;
  }
  .rouletteContainer {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 1000;
    @media (max-width: 992px) {
      z-index: 5000;
    }
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dark-blue);
      border-radius: 24px;
      filter: drop-shadow(0px 4px 50px rgba(255, 255, 255, 0.2));
      .content {
        height: auto;
        width: 550px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        @media (max-width: 992px) {
          width: 300px;
        }
        .titleContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 12px;
          background-color: var(--primary-color);
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          padding: 6px 16px;
          .title {
            color: #fff;
            font-size: 20px;
          }
          .exitButton {
            cursor: pointer;
          }
        }
        .infoContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .description {
            text-align: center;
            margin: 12px 56px;
            font-size: 16px;

            @media (max-width: 992px) {
              margin: 8px 4px;
            }
          }
          .coins {
            color: var(--secondary-color);
            font-size: 20px;
          }
          .buttons {
            display: flex;
            justify-content: center;
            .buttonContainer {
              margin: 8px 16px;
              margin-bottom: 24px;
              display: flex;
              .buttonText {
                padding: 8px 16px;
                font-size: 18px;
                background-color: var(--secondary-color);
                border-radius: 24px;
                &:hover {
                  background-color: #d71838;
                  cursor: pointer;
                }
              }
              .buttonTextGray {
                padding: 8px 16px;
                font-size: 18px;
                background-color: transparent;
                border: 1px solid gray;
                border-radius: 24px;
                transition: all 1s ease;
                transform: scale(1);
                &:hover {
                  background-color: gray;
                  transition: all 1s ease;
                  transform: scale(0.5);
                  color: black;
                  cursor: pointer;
                }
              }
            }
          }
          .wheelWrapper {
            position: relative;
            > div:first-child {
              max-width: 320px;
              max-height: 320px;
              @media (max-width: 992px) {
                max-width: 200px;
                max-height: 200px;
              }
            }
            .pinger {
              position: absolute;
              pointer-events: none;
              top: 13px;
              right: 5px;
              z-index: 100;
              width: 64px;
              height: auto;
              @media (max-width: 992px) {
                width: 38px;
              }
            }
          }
          .spinButton {
            cursor: pointer;
            margin: 12px auto;
          }
          .message {
            margin-bottom: 2px;
            font-size: 18px;
            color: var(--secondary-color);
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .backgroundContainer {
    .container {
      padding-top: 100px;
      margin-left: 30px;
      text-align: initial;
      .title {
        font-weight: bold;
        font-size: 22px;
      }
      .subtitle {
        font-size: 18px;
        opacity: 0.7;
        margin-bottom: 28px;
      }
      .campainsContainer {
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 15px 0;

        .campaignContainer {
          margin-right: 40px;
        }
      }
    }
    .arcade {
      display: initial;
      position: absolute;
      opacity: 0.4;
      bottom: 0;
      right: 0;
    }
  }
}

@keyframes shake {
  2% {
    transform: translate(5px, 7px) rotate(0.5deg);
  }
  4% {
    transform: translate(5px, 9px) rotate(3.5deg);
  }
  6% {
    transform: translate(-2px, -5px) rotate(3.5deg);
  }
  8% {
    transform: translate(4px, 8px) rotate(-1.5deg);
  }
  10% {
    transform: translate(2px, 9px) rotate(-0.5deg);
  }
  12% {
    transform: translate(-5px, -4px) rotate(-2.5deg);
  }
  14% {
    transform: translate(-6px, -1px) rotate(3.5deg);
  }
  16% {
    transform: translate(-8px, 8px) rotate(2.5deg);
  }
  18% {
    transform: translate(4px, 5px) rotate(-0.5deg);
  }
  20% {
    transform: translate(3px, -3px) rotate(3.5deg);
  }
  22% {
    transform: translate(0px, -6px) rotate(-1.5deg);
  }
  24% {
    transform: translate(-6px, -6px) rotate(3.5deg);
  }
  26% {
    transform: translate(-8px, -6px) rotate(0.5deg);
  }
  28% {
    transform: translate(-3px, -6px) rotate(1.5deg);
  }
  30% {
    transform: translate(7px, 8px) rotate(-1.5deg);
  }
  32% {
    transform: translate(7px, 6px) rotate(2.5deg);
  }
  34% {
    transform: translate(-7px, -7px) rotate(-2.5deg);
  }
  36% {
    transform: translate(7px, 3px) rotate(-0.5deg);
  }
  38% {
    transform: translate(-6px, -1px) rotate(3.5deg);
  }
  40% {
    transform: translate(-3px, 4px) rotate(-0.5deg);
  }
  42% {
    transform: translate(-1px, 10px) rotate(3.5deg);
  }
  44% {
    transform: translate(9px, 3px) rotate(3.5deg);
  }
  46% {
    transform: translate(-3px, 8px) rotate(-2.5deg);
  }
  48% {
    transform: translate(-5px, 7px) rotate(3.5deg);
  }
  50% {
    transform: translate(-9px, -5px) rotate(0.5deg);
  }
  52% {
    transform: translate(2px, 4px) rotate(0.5deg);
  }
  54% {
    transform: translate(2px, -9px) rotate(-2.5deg);
  }
  56% {
    transform: translate(-2px, 9px) rotate(-1.5deg);
  }
  58% {
    transform: translate(0px, 4px) rotate(0.5deg);
  }
  60% {
    transform: translate(-6px, 10px) rotate(3.5deg);
  }
  62% {
    transform: translate(-1px, 3px) rotate(2.5deg);
  }
  64% {
    transform: translate(5px, -5px) rotate(0.5deg);
  }
  66% {
    transform: translate(5px, 3px) rotate(3.5deg);
  }
  68% {
    transform: translate(1px, -7px) rotate(-2.5deg);
  }
  70% {
    transform: translate(7px, 3px) rotate(0.5deg);
  }
  72% {
    transform: translate(-9px, -8px) rotate(-2.5deg);
  }
  74% {
    transform: translate(9px, 10px) rotate(-1.5deg);
  }
  76% {
    transform: translate(-1px, -7px) rotate(1.5deg);
  }
  78% {
    transform: translate(-7px, -4px) rotate(-1.5deg);
  }
  80% {
    transform: translate(8px, -3px) rotate(3.5deg);
  }
  82% {
    transform: translate(9px, 5px) rotate(0.5deg);
  }
  84% {
    transform: translate(-4px, -7px) rotate(1.5deg);
  }
  86% {
    transform: translate(-7px, 9px) rotate(2.5deg);
  }
  88% {
    transform: translate(0px, 10px) rotate(1.5deg);
  }
  90% {
    transform: translate(7px, 4px) rotate(-2.5deg);
  }
  92% {
    transform: translate(-9px, 10px) rotate(-1.5deg);
  }
  94% {
    transform: translate(4px, -8px) rotate(0.5deg);
  }
  96% {
    transform: translate(9px, 0px) rotate(1.5deg);
  }
  98% {
    transform: translate(8px, -3px) rotate(1.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.container {
  .label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .input {
    font-size: 16px;
    background-color: var(--black-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.15);
    border-radius: 30px;
    padding: 12px 18px !important;
    outline: none;
    width: 100%;
    transition: 0.3s ease;
    &:focus {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
    &::placeholder {
      color: var(--white-color);
      opacity: 0.3;
    }
  }
}

@media (min-width: 992px) {
  .label {
    font-size: 12px;
    margin-bottom: 7px;
  }
  .input {
    font-size: 12px !important;
    padding: 9px 13px !important;
  }
}

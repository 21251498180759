.container {
  display: flex;
  .input {
    display: none;
    &:checked ~ .customInput {
      border: 1px solid rgba(255, 255, 255, 0.3);
      .indicator {
        background-color: var(--secondary-color);
      }
    }
  }
  .customInput {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    margin-right: 14px;
    overflow: hidden;
    padding: 2px;
    transition: 0.3s ease;
    cursor: pointer;
    .indicator {
      height: 100%;
      background-color: transparent;
      border-radius: 2px;
      transition: 0.3s ease;
    }
  }
  .label {
    cursor: pointer;
    user-select: none;
  }
}

.terms {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  .title {
    padding: 0 1.5rem;
    margin-top: 3rem;
    text-transform: uppercase;
    h2 {
      font-weight: 600;
    }
    h3 {
      color: rgba($color: #fff, $alpha: 0.5);
      margin: 1rem 0;
      font-weight: 400;
    }
  }
  .info {
    //text-transform: capitalize;
    padding: 0 1.5rem;
    .single {
      margin: 2rem 0;
      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
      }
      p {
        color: #8c8e9b;
        margin-top: 1.5rem;
        font-family: "Whitney";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
  h3 {
    font-size: clamp(15px, 6vw, 20px);
  }

  .bcgSvg {
    svg {
      opacity: 0.3;
    }
    img,
    svg {
      user-select: none;
      pointer-events: none;
    }
    .topLeftCube {
      position: absolute;
      top: 100px;
    }
    .topRightCube {
      position: absolute;
      right: 0;
    }
    .bottomRightTriangle {
      position: absolute;
      bottom: 45vh;
      right: 0;
      z-index: 1;
      width: 80px;
      img {
        width: 100%;
      }
    }
    .bottomLeftFigure {
      position: absolute;
      bottom: 45vh;
      left: 10%;
      z-index: 1;
      width: 80px;
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .terms {
    text-align: left;
    .title {
      h2 {
        color: #e87588;
      }
    }
    .info {
      padding: 0 5rem 0 1.5rem;
    }
    .bcgSvg {
      .topLeftCube {
        width: 300px;
        right: 0;
        top: 70vh;
        svg {
          width: 200px;
        }
      }
      .topRightCube {
        top: 70vh;
        svg {
          width: 200px;
          height: 200px;
        }
      }
      .bottomRightTriangle {
        top: 5%;
        width: 150px;
        overflow: hidden;
        img {
          transform: translateX(40%) rotate(-20deg);
        }
      }
      .bottomLeftFigure {
        top: 0;
        left: 60%;
        z-index: -1;
      }
    }
  }
}

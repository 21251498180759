.background {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    position: relative;
    background-color: var(--black-color);
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
    padding: 32px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    border-radius: 32px;
    max-width: calc(100% - 48px);
    .closeButton {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .userContainer {
      width: 100%;
      display: flex;
      .avatarContainer {
        .avatar {
          height: 48px;
          width: 48px;
          object-fit: cover;
          border-radius: 24px;
        }
      }
      .content {
        margin-left: 12px;
        flex: 1;
        overflow: hidden;
        .nameContainer {
          display: flex;
          align-items: center;
          .level {
            margin-right: 8px;
            padding: 2px 6px;
            background-color: var(--gray-color);
            opacity: 0.8;
            color: var(--secondary-color);
            border-radius: 5px;
            font-size: 12px;
          }
          .name {
            font-size: 18px;
            font-weight: 700;
          }
        }
        .xp {
          margin-top: 8px;
          font-size: 14px;
          color: var(--gray-color);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .progresContainer {
      margin: 20px 0;
    }
    .cardsContainer {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .card {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        max-width: 180px;
        width: 100%;
        padding: 12px;
        margin: 8px;
        .value {
          margin-bottom: 12px;
          text-align: center;
          font-size: 18px;
          font-weight: 700;
        }
        .label {
          text-align: center;
          font-size: 15px;
          font-weight: 700;
          opacity: 0.5;
        }
      }
    }
    .graphContainer {
      .label {
        font-size: 14px;
        text-align: center;
        margin-bottom: 24px;
      }
    }
  }
}

@media (min-width: 992px) {
  .background {
    .container {
      width: 50%;
      height: auto;
      .cardsContainer {
        flex-direction: row;
        justify-content: space-between;
      }
      .userContainer {
        .content {
          .nameContainer {
            display: flex;
            align-items: center;
            .level {
            }
            .name {
              font-size: 24px;
              font-weight: 700;
            }
          }
          .xp {
            font-size: 18px;
          }
        }
      }
    }
  }
  .graphContainer {
    width: 75%;
    height: auto;
    margin: 0 auto;
  }
}

.container {
  .title {
    text-align: center;
    margin-bottom: 32px;
    font-size: 18px;
    font-weight: 700;
  }
  .table {
    margin-bottom: 48px;
    .tableRow {
      display: flex;
      margin-bottom: 12px;
      justify-content: space-between;
      .label {
        font-size: 15px;
        opacity: 0.5;
      }
      .value {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        word-break: break-all;
        margin-left: 24px;
        text-align: right;
        .certeficateButton {
          min-width: 140px;
        }
        .icon {
          margin-left: 4px;
        }
      }
    }
  }
  .flexRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
    .label {
      font-size: 15px;
      opacity: 0.5;
      white-space: nowrap;
    }
    .inputContainer {
      display: flex;
      align-items: center;
      margin-left: 12px;
      padding: 4px;
      border-radius: 32px;
      background-color: rgba(255, 255, 255, 0.1);
      .input {
        outline: none;
        border: none;
        padding: 0 12px;
        font-weight: 700;
        width: 100%;
        background-color: transparent;
        color: var(--secondary-color);
      }
    }
    .switchContainer {
      display: flex;
      align-items: center;
      .switchLabel {
        font-size: 15px;
        font-weight: 700;
      }
      .input {
        display: none;
        &:checked {
          ~ .switchWrapper {
            .switchIndicator {
              left: calc(100% - 16px);
            }
          }
        }
      }
      .switchWrapper {
        position: relative;
        height: 32px;
        width: 64px;
        background-color: rgba(255, 255, 255, 0.1);
        margin: 0 12px;
        border-radius: 16px;
        .switchIndicator {
          position: relative;
          height: 24px;
          width: 24px;
          top: 50%;
          left: 16px;
          border-radius: 12px;
          transform: translate(-50%, -50%);
          background-color: var(--secondary-color);
          transition: 0.3s ease;
        }
      }
    }
    .submit {
      border: none;
      font-size: 13px;
      font-weight: 700;
      border-radius: 24px;
      padding: 8px 12px;
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

@media (min-width: 992px) {
  .container {
    display: flex;
    flex: 50%;
    flex-direction: row;
    justify-content: space-between;
    .section {
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
      border-radius: 30px;
      flex: 1 0 40%;
      padding: 20px;
      margin: 10px;
      .title {
        font-size: 30px;
        text-align: left;
      }
      .table {
        .tableRow {
          .label {
            font-size: 16px;
          }
          .value {
            font-size: 16px;
          }
        }
      }
      .flexRow {
        .label {
          font-size: 16px;
        }
      }
    }
  }
}

.userContainer {
  padding: 5px;
  flex: 0 0 auto;
  padding-right: 30px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  position: relative;
  &.active {
    transition: all 1s;
    animation: mymove2 1s;
    animation-timing-function: ease-in-out;
    background-color: #962150;
    padding: 0 60px;
    height: 100%;
    width: 180px;
    display: flex;
    margin: 0;
    .avatar {
      position: absolute;
      animation: mymove 1s;
      animation-timing-function: ease-in-out;
      //  margin-right: 100px;
      // margin-left: -32px;
      //  margin: 0 auto;
      height: 60px;
      width: 60px;
      display: block;
      margin: auto;
    }
    .userContent {
      animation-name: dissapear;
      animation-duration: 200ms;
      animation-fill-mode: forwards;
    }
  }
  .avatar {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  }
  .userContent {
    margin-left: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .userName {
      white-space: nowrap;
      width: fit-content;
      opacity: 0.7;
      font-size: 14px;
    }
    .earnendContainer {
      display: flex;
      align-items: center;
      .coinsEarned {
        font-weight: 16px;
        font-weight: 700;
      }
      .coinsEarnedIcon {
        margin-left: 4px;
      }
    }
  }
}

@keyframes mymove {
  from {
    height: 40px;
    width: 40px;
  }
  to {
    height: 60px;
    width: 60px;
    display: block;
    margin: auto;
  }
}

@keyframes mymove2 {
  0% {
    padding: 0;
  }
  50% {
    padding: 0;
  }
  100% {
    padding: 0 60px;
  }
}

@keyframes dissapear {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

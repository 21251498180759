.container {
  .label {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .input {
    position: relative;
    font-size: 16px;
    background-color: var(--black-color);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.15);
    border-radius: 30px;
    padding: 12px 18px;
    outline: none;
    width: 100%;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.3);
    }
    .placeholder {
      color: var(--white-color);
      opacity: 0.3;
    }
    .selectWrapper {
      position: absolute;
      background-color: var(--light-blue);
      top: 0;
      right: 0;
      border-radius: 20px;
      border-top-left-radius: 0;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      z-index: 3;
      .selectContainer {
        overflow-y: scroll;
        max-height: 150px;
        padding: 0 10px;
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          height: 80%;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--secondary-color);
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: var(--dark-blue);
        }
        .option {
          margin: 10px 0;
          &:first-of-type {
            margin-top: 0;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          .optionLabel {
            font-size: 18px;
            text-align: center;
            opacity: 0.3;
            transition: 0.3s ease;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .focus {
    border: 1px solid rgba(255, 255, 255, 0.3);
    .selectWrapper {
      padding: 20px 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      top: -1px;
      right: -1px;
      max-height: 200px;
    }
  }
}

.app {
  .header {
    .header {
      .btn {
        background-color: var(--secondary-color);
        font-size: 16px;
        padding: 6px 12px;
        border-radius: 12px;
      }
    }
  }
}

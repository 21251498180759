.wrapper {
  height: 100%;
  background: #16213e;
  position: relative;
  overflow-y: auto;
  margin: 65px 0;
  padding-bottom: 100px;
  width: 100vw;

  .background {
    position: relative;
    @media (min-width: 992px) {
      margin: 0;
      padding-bottom: 0;
      position: fixed;
    }
    .container {
      background-color: var(--dark-blue);

      .popupHeader {
        display: none;
      }
      .category {
        padding: 10px 0;
        display: flex;
        justify-content: space-evenly;
        background: #16213e;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
        .button {
          background-color: transparent;
          border: none;
          font-weight: bold;
          font-size: 20px;
          line-height: 25px;
          text-transform: capitalize;
          .icon {
            margin: 0 4px;
          }
        }
      }
      .offerContainer {
        padding: 15px 20px;
        transform: translateX(-10px);
        &.active {
          //filter: blur(10px);
          //pointer-events: none;
        }
      }
      .unsupportedContainer {
        text-align: center;
        margin-top: 32px;
        .imageContainer {
          display: flex;
          justify-content: center;
          align-items: flex-end;

          .cactus {
            width: 84px;
            height: auto;
          }
        }
        .countryTitle {
          color: #53f2d4;
          margin: 24px auto;
          font-size: 20px;
          font-weight: bold;
        }
        .countryDescription {
          margin: 12px 36px;
          opacity: 0.8;
        }
      }
    }
    .popupContainer {
      position: fixed;
      width: 99%;
      animation: shake 0.32s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      left: 0;
      top: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;
      .loginPopup {
        display: flex;
        flex-direction: column;
        //  align-items: center;
        justify-content: flex-start;
        padding-top: 10px;
        height: 140px;
        width: 400px;
        background: var(--primary-color);
        border: 1px solid rgba(255, 246, 246, 0.05);
        box-sizing: border-box;
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 30px;
        .login {
          text-align: center;
          font-size: 24px;
          margin: 15px;
          .exit {
            position: fixed;
            margin-top: -16px;
            margin-left: 40px;
          }
        }
        .buttonContainer {
          display: flex;
          justify-content: space-evenly;
          button {
            .buttonContent {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              height: 100%;
              .icon {
                margin-right: 10px;
              }
            }
          }
        }
      }
      .offerContainer {
        padding: 15px 20px;

        &.active {
          filter: blur(10px);
          pointer-events: none;
        }
      }
    }
    .qrContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .iFrameBackground {
    @media (min-width: 992px) {
      margin: 0;
      padding-bottom: 0;
      position: fixed;
    }
    .iFrameContainer {
      background-color: var(--dark-blue);

      .iFramePopupHeader {
        display: none;
      }
      .iframe {
        height: 100vh;
        width: calc(100% + 18px);
        overflow: hidden;
        border: none;
        @media (min-width: 992px) {
          width: calc(100% + 20px);
          height: 100%;
        }
      }
    }
  }
  .totalContainer {
    .glassesContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .glass {
        margin: 0;
        pad: 0;
        width: 50%;
        height: auto;
        z-index: 1;
      }
      .rotaterLeft {
        position: absolute;
        width: 18.5%;
        height: auto;
        z-index: 2;
        left: 27.8%;
        margin-top: 4px;
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
      .rotaterRight {
        position: absolute;
        width: 18.5%;
        height: auto;
        z-index: 2;
        right: 27.6%;
        margin-top: 4px;
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
      }
    }
    .loadingScreen {
      transform: translateY(-150px);
    }
  }
}

@media (min-width: 992px) {
  .wrapper {
    background-color: transparent;
    position: fixed;
    .totalContainer::-webkit-scrollbar {
      display: none;
    }
    .background {
      position: fixed;
      z-index: 2000;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      .popupContainer {
        position: absolute;
      }
      .container {
        background-color: var(--black-color);
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        max-width: calc(100% - 48px);
        height: 70%;
        width: 50%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .popupHeader {
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
          border-radius: 30px 30px 0px 0px;
          background-color: var(--primary-color);
          padding: 20px 22px;
          display: flex;
          justify-content: space-between;
          .kefLogo {
            width: 90px;
            height: auto;
          }
        }
        .category {
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
        }
        .offerContainer {
          transform: translateX(0);
        }
        .totalContainer {
          max-height: 100%;
          overflow-y: scroll;
          margin-top: 15px;
          .glassesContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .glass {
              margin: 0;
              pad: 0;
              width: 50%;
              height: auto;
              z-index: 1;
            }
            .rotaterLeft {
              position: absolute;
              width: 18.5%;
              height: auto;
              z-index: 2;
              left: 27.8%;
              margin-top: 4px;
              transform: rotate(20deg);
              -webkit-animation: spin 2s linear infinite;
              -moz-animation: spin 2s linear infinite;
              animation: spin 2s linear infinite;
            }
            .rotaterRight {
              position: absolute;
              width: 18.5%;
              height: auto;
              z-index: 2;
              right: 27.6%;
              margin-top: 4px;
              -webkit-animation: spin 2s linear infinite;
              -moz-animation: spin 2s linear infinite;
              animation: spin 2s linear infinite;
            }
          }
          .loadingScreen {
            transform: translateY(-150px);
          }
        }
      }
    }
    .iFrameBackground {
      position: fixed;
      z-index: 2000;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      .popupContainer {
        position: absolute;
      }
      .iFrameContainer {
        position: "relative";

        position: "relative";
        .captchaWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 48px;
        }

        background-color: var(--black-color);
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        max-width: calc(100% - 48px);
        height: 90%;
        width: 60%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .iFramePopupHeader {
          border: 1px solid rgba(255, 255, 255, 0.1);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
          border-radius: 30px 30px 0px 0px;
          background-color: var(--primary-color);
          padding: 20px 22px;
          display: flex;
          justify-content: space-between;
          .kefLogo {
            width: 90px;
            height: auto;
          }

          .iframe {
            height: 100vh;
            width: calc(100% + 18px);
            overflow: hidden;
            border: none;
            @media (min-width: 992px) {
              width: calc(100% + 20px);
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@keyframes shake {
  2% {
    transform: translate(5px, 7px) rotate(0.5deg);
  }
  4% {
    transform: translate(5px, 9px) rotate(3.5deg);
  }
  6% {
    transform: translate(-2px, -5px) rotate(3.5deg);
  }
  8% {
    transform: translate(4px, 8px) rotate(-1.5deg);
  }
  10% {
    transform: translate(2px, 9px) rotate(-0.5deg);
  }
  12% {
    transform: translate(-5px, -4px) rotate(-2.5deg);
  }
  14% {
    transform: translate(-6px, -1px) rotate(3.5deg);
  }
  16% {
    transform: translate(-8px, 8px) rotate(2.5deg);
  }
  18% {
    transform: translate(4px, 5px) rotate(-0.5deg);
  }
  20% {
    transform: translate(3px, -3px) rotate(3.5deg);
  }
  22% {
    transform: translate(0px, -6px) rotate(-1.5deg);
  }
  24% {
    transform: translate(-6px, -6px) rotate(3.5deg);
  }
  26% {
    transform: translate(-8px, -6px) rotate(0.5deg);
  }
  28% {
    transform: translate(-3px, -6px) rotate(1.5deg);
  }
  30% {
    transform: translate(7px, 8px) rotate(-1.5deg);
  }
  32% {
    transform: translate(7px, 6px) rotate(2.5deg);
  }
  34% {
    transform: translate(-7px, -7px) rotate(-2.5deg);
  }
  36% {
    transform: translate(7px, 3px) rotate(-0.5deg);
  }
  38% {
    transform: translate(-6px, -1px) rotate(3.5deg);
  }
  40% {
    transform: translate(-3px, 4px) rotate(-0.5deg);
  }
  42% {
    transform: translate(-1px, 10px) rotate(3.5deg);
  }
  44% {
    transform: translate(9px, 3px) rotate(3.5deg);
  }
  46% {
    transform: translate(-3px, 8px) rotate(-2.5deg);
  }
  48% {
    transform: translate(-5px, 7px) rotate(3.5deg);
  }
  50% {
    transform: translate(-9px, -5px) rotate(0.5deg);
  }
  52% {
    transform: translate(2px, 4px) rotate(0.5deg);
  }
  54% {
    transform: translate(2px, -9px) rotate(-2.5deg);
  }
  56% {
    transform: translate(-2px, 9px) rotate(-1.5deg);
  }
  58% {
    transform: translate(0px, 4px) rotate(0.5deg);
  }
  60% {
    transform: translate(-6px, 10px) rotate(3.5deg);
  }
  62% {
    transform: translate(-1px, 3px) rotate(2.5deg);
  }
  64% {
    transform: translate(5px, -5px) rotate(0.5deg);
  }
  66% {
    transform: translate(5px, 3px) rotate(3.5deg);
  }
  68% {
    transform: translate(1px, -7px) rotate(-2.5deg);
  }
  70% {
    transform: translate(7px, 3px) rotate(0.5deg);
  }
  72% {
    transform: translate(-9px, -8px) rotate(-2.5deg);
  }
  74% {
    transform: translate(9px, 10px) rotate(-1.5deg);
  }
  76% {
    transform: translate(-1px, -7px) rotate(1.5deg);
  }
  78% {
    transform: translate(-7px, -4px) rotate(-1.5deg);
  }
  80% {
    transform: translate(8px, -3px) rotate(3.5deg);
  }
  82% {
    transform: translate(9px, 5px) rotate(0.5deg);
  }
  84% {
    transform: translate(-4px, -7px) rotate(1.5deg);
  }
  86% {
    transform: translate(-7px, 9px) rotate(2.5deg);
  }
  88% {
    transform: translate(0px, 10px) rotate(1.5deg);
  }
  90% {
    transform: translate(7px, 4px) rotate(-2.5deg);
  }
  92% {
    transform: translate(-9px, 10px) rotate(-1.5deg);
  }
  94% {
    transform: translate(4px, -8px) rotate(0.5deg);
  }
  96% {
    transform: translate(9px, 0px) rotate(1.5deg);
  }
  98% {
    transform: translate(8px, -3px) rotate(1.5deg);
  }
  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

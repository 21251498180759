.container {
  background-color: var(--primary-color);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.4);
  padding: 12px;
  .iconsContainer {
    display: flex;
    justify-content: space-evenly;
    .iconWrapper {
      border: none;
      background: transparent;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        height: 32px;
      }
      .label {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 700;
      }
      &.active {
        p {
          color: #e94560;
        }
        .icon {
          path {
            fill: #e94560;
          }
        }
      }
      &.active2 {
        p {
          color: #ee768a;
        }
        .icon {
          path {
            fill: #ee768a;
          }
        }
      }
    }
  }
}

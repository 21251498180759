.questionContainer {
  margin: 20px 100px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 12px;
  @media (max-width: 750px) {
    margin: 20px 30px;
  }
  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .istitle {
      font-size: 18px;
      line-height: 21px;
      font-weight: 400;
      color: white;
      cursor: pointer;
      width: 100%;
      text-align: left;
      opacity: 0.7;
      font-weight: lighter;
      text-transform: capitalize;
    }
  }
  .childrenWrapper {
    height: 0;
    overflow: hidden;
    transition: 0.2s ease-out;

    &.active {
      padding: 18px 18px 18px 0;
      //margin-top: 12px;
      height: fit-content;
    }
  }
}

.backgroundContainer {
  margin-top: 48px;
  margin-bottom: 48px;
  .dateContainer {
    display: inline-flex;
    margin: auto 20%;

    .timeDate {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.5;
      padding-right: 12px;
      @media (max-width: 870px) {
        text-align: center;
      }
    }
    .timeline {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: capitalize;
      color: #ffffff;
      opacity: 0.5;
    }
  }
  .title {
    margin: 10px 20%;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-transform: uppercase;
    color: #ffe483;
    z-index: 50;
    margin-bottom: 80px;
    @media (max-width: 870px) {
      font-size: 24px;
      text-align: center;
    }
  }
  .mainPicContainer {
    position: relative;
    text-align: center;
    z-index: 10;
    margin-bottom: 50px;
    .blogPhoto {
      text-align: center;
      z-index: 10;
      border: 1px solid #000000;
      box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.1);
      border-radius: 30px;
      width: 822px;
      height: 518px;
      object-fit: cover;
      @media (max-width: 870px) {
        width: 80%;
        height: auto;
      }
    }
    .eyes {
      position: absolute;
      top: -150px;
      right: 10%;
      z-index: 10;
      @media (max-width: 870px) {
        display: none;
      }
    }
    .brain {
      position: absolute;
      bottom: 50px;
      left: 150px;
      z-index: -1;
      @media (max-width: 870px) {
        display: none;
      }
    }
  }
  .subContent {
    margin: auto 20%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 13px;
    text-transform: capitalize;
    color: #fafafa;
    mix-blend-mode: normal;
    margin-bottom: 50px;
    text-transform: initial;
    @media (max-width: 870px) {
      margin: 0 30px;
    }
    .subtitle {
      font-size: 26px;
      margin: 24px auto;
      color: #ffe483;
    }
    p {
      text-transform: none;
    }
  }
}

.allcontent {
  .container {
    position: relative;
    height: 100%;
    margin-top: 18px;
    @media (min-width: 992px) {
      margin-top: 0;
    }
    .background {
      margin-top: 50px;
      padding-top: 36px;
      background-color: var(--black-color);
      // background-image: url(/assets/login/background.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      width: 100%;
      position: relative;
      position: fixed;
      @media (min-width: 992px) {
        margin-top: 0px;
        position: absolute;
      }
      .content {
        position: relative;
        z-index: 1;
        padding: 0 20px;
        max-height: 100%;
        overflow-y: auto;
        .closeButton {
          display: none;
        }
        .dotsContainer {
          .certeficateContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 32px;
          }
          .textTitle {
            margin-top: 36px;
            margin-bottom: 64px;
            text-align: center;
          }
          .treesImg {
            margin-top: 36px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: auto;
            height: 250px;
          }
          .treesText {
            margin-top: 48px;
            text-align: center;
            .treesTextQuantity {
              color: var(--secondary-color);
            }
          }
          .quantityContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 12px;
            margin-top: 64px;
            gap: 12px;
            .quantityButton {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 24px;
              padding: 6px;
              width: 36px;
              height: 36px;
              border-radius: 8px;
              background-color: var(--secondary-color);
              &:hover {
                cursor: pointer;
                background-color: var(--pink-color);
                user-select: none;
              }
            }
          }
        }
        .flexButtonContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          .calculations {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            visibility: hidden; // Remove this to show fee and coinprice

            .fee {
              display: flex;

              flex-direction: row;
              justify-content: space-between;
              padding: 6px 10px;
              font-weight: normal;
              font-size: 15px;
              text-transform: capitalize;
              color: #ffffff;
              opacity: 0.5;
            }
            .priceCoin {
              display: flex;
              gap: 70px;
              flex-direction: row;
              justify-content: space-between;
              padding: 6px 10px;
              font-size: 15px;
              line-height: 17px;
              text-transform: capitalize;
              color: #ffffff;
            }
          }
        }
        .errorContainer {
          background-color: var(--secondary-color);
          margin-bottom: 24px;
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 12px;
        }
        //IFRAME CSS
        .iframe {
          margin-top: 12px;
          width: 100%;
          height: 70vh;
        }
      }
      .withdrawButton {
        display: flex;
        margin-top: 64px;
        justify-content: center;
      }
      .cloudL {
        position: absolute;
        bottom: 10px;
        left: 0;
      }
      .cloudR {
        position: absolute;
        bottom: 70px;
        right: 0;
      }
      .cloudBot {
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;
      }
    }
  }
}

@media (min-width: 992px) {
  .allcontent {
    .container {
      position: absolute;
      z-index: 2000;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;

      .background {
        background-color: var(--black-color);
        filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.2));
        padding: 0;
        border: 1px solid rgba(255, 255, 255, 0.05);
        box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
        border-radius: 32px;
        max-width: calc(100% - 48px);
        height: auto;
        width: 50%;
        min-height: 80vh;
        max-height: 80vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        padding: 20px;
        .content {
          .closeButton {
            display: initial;
            float: right;
          }
          .adressContainer {
            padding: 20px 10px;
            margin: 20px auto;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 32px;

            .inputContainer {
              margin: 0;
              background-color: transparent;
              border: none;
              box-shadow: none;
              justify-content: space-between !important;
              align-items: center;
            }
            .input {
              background-color: #252941;
              border: 1px solid rgba(0, 0, 0, 0.05);
              box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.15);
              justify-content: space-around;
              flex: 0;
              width: 70%;
              flex-basis: auto;
              padding: 12px 18px;
              margin-right: 15px;
            }
          }
        }
        .content::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        .ammountSelected {
          display: flex !important;
          justify-content: space-around;
          flex-wrap: wrap;
          .select {
            padding: 30px 30px !important;
          }
        }
      }
      .cloudL {
        display: none;
      }
      .cloudR {
        display: none;
      }
      .cloudBot {
        display: none;
      }
    }
  }
}

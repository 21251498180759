.wrapper {
  width: 100vw;
  min-height: 100vh;
  padding-top: 48px;
  padding-bottom: 84px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-blue);
  .backButton {
    position: absolute;
    top: 24px;
    left: 24px;
  }
  .containerTitle {
    font-size: 48px;
    text-align: center;
    opacity: 0;
    animation: fade-in 3s forwards;
    margin-top: 24px;
    padding: 0 15%;
    @media (max-width: 450px) {
      font-size: 24px;
      padding: 0 12px;
    }
  }
  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .versus {
      width: 250px;
      fill: white;
      color: white;
    }
    .firstCampaign {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      min-height: 400px;
      max-width: 300px;
      box-shadow: 0px 0px 20px rgba(52, 93, 157, 0.6);
      border-radius: 30px;
      padding: 36px;
    }
    .campaignImage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 0px 20px rgba(52, 93, 157, 0.6);
      border-radius: 30px;
      min-width: 196px;
      max-width: 196px;
      min-width: 200px;
      min-height: 200px;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 0px 20px rgba(233, 69, 96, 0.3);
      }
    }
    .campaignTitle {
      text-align: left;
      margin: 12px auto;
    }
    .campaignPrice {
      text-align: left;
    }
    .buttonContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      padding-top: 24px;
      .campaignButton {
        width: 200px;
        height: auto;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .selectDeviceContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    opacity: 0;
    animation: fade-in 1.5s forwards;
    transform: translateY(-8990px);
    margin-top: 5%;
    position: relative;
    .deviceContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      .campaignButton {
        width: 200px;
        height: auto;
        &:hover {
          cursor: pointer;
        }
      }
      .iphone {
        width: 150px;
        opacity: 0.5;
      }
      .android {
        width: 150px;
        opacity: 0.5;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-8990px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
